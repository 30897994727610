import { useModal } from '@ebay/nice-modal-react';
import { Box, Divider, Stack } from '@mui/material';
import randomBytes from 'randombytes';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { updatePayment } from '../../../Api';
import { Payment } from '../../../Api/Payment';
import { useVariantSelectValidation } from '../../../Components/VariantSelect/use-variant-select-validation';
import { VariantSelect } from '../../../Components/VariantSelect/VariantSelect';
import {
  useGetBookingsForEvent,
  useGetBookingsSummary,
} from '../../../Hooks/data/useBookings';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { TCreateBookingPayload, useBookings } from '../../../Hooks/useBookings';
import { IExperience } from '../../../Hooks/useExperience';
import { Product, useProducts } from '../../../Hooks/useProducts';
import { useTranslate } from '../../../Hooks/useTranslate';
import { getGuestCount, TEvent } from '../../../Utils/eventHelpers';
import { CreateBookingFormActions } from './create-booking-form-actions';
import { CustomDataInputs } from './inputs/custom-data';
import { CustomerInformation } from './inputs/customer-information';
import { InternalNote } from './inputs/internal-note';
import { Options } from './inputs/options';

export type CreateBookingFormInputs = {
  customer: {
    name: string;
    email: string;
    phone?: string;
  };
  language?: string;
  internalNote?: string;
  guests: { [k: string]: number };
  customDataInputs?: {
    inputs: { name: string; value: string }[];
  }[];
  shouldCreatePaymentLink: boolean;
  shouldSendNotification: boolean;
};

type CreateBookingFormProps = {
  event: TEvent;
  experience: IExperience;
  handleClose: (shouldRemove?: boolean) => Promise<void>;
};

const uuid = () => randomBytes(16).toString('hex');

export const CreateBookingForm: FC<CreateBookingFormProps> = ({
  event,
  experience,
  handleClose,
}) => {
  const { isSm } = useResponsive();
  const [id, setId] = useState(uuid());
  const [paymentId, setPaymentId] = useState(uuid);
  const { t } = useTranslate('dialogs.createBooking');
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const { createBooking } = useBookings(event.id);
  const { bookingsForEvent } = useGetBookingsForEvent(event.id);
  const { bookingsSummary } = useGetBookingsSummary(event.id);

  const { products } = useProducts(experience.id);

  const defaultValues = useMemo(() => {
    return {
      shouldSendNotification: true,
      language: event.languages[0],
    };
  }, [event]);

  const formMethods = useForm<CreateBookingFormInputs>({
    defaultValues,
  });

  const { handleSubmit, control, reset } = formMethods;

  const variantSelectValidation = useVariantSelectValidation(
    event.experienceId
  );

  const {
    field: guests,
    formState: { errors },
  } = useController({
    name: 'guests',
    control,
    defaultValue: {},
    rules: {
      validate: (value) => {
        try {
          variantSelectValidation.validateSync(value);

          return true;
        } catch (error) {
          if (error instanceof ValidationError) {
            return error.message;
          }

          return false;
        }
      },
    },
  });

  const handleUpdateGuests = (value: { [k: string]: number }) => {
    guests.onChange(value);
  };

  const totalGuests = getGuestCount(guests.value);

  const onSubmit = async (data: CreateBookingFormInputs) => {
    setIsLoading(true);
    const {
      customer,
      language,
      guests,
      internalNote,
      shouldCreatePaymentLink,
      shouldSendNotification,
      customDataInputs,
    } = data;

    const skipBookingConfirmation = !shouldSendNotification;
    const payload: TCreateBookingPayload = {
      customer,
      ...(internalNote && { internalNote }),
      language,
      items: guests,
      eventId: event.id,
      experienceId: event.experienceId,
      startDateTime: event.startDateTime,
      endDateTime: event.endDateTime as string,
      channel: 'manual',
      source: 'holdbar',
      status: 'unpaid',
      ...(shouldCreatePaymentLink && {
        paymentId,
      }),
      metaData: {
        skipBookingConfirmation,
      },
      customDataInputs,
    };

    const proms = Promise.all([
      createBooking.mutateAsync({ id, ...payload }),
      shouldCreatePaymentLink &&
        updatePayment(paymentId, {
          subject: 'booking',
          subjectId: id,
          items: mapGuestsToOffers(products, guests),
        }),
    ]);

    const callback = async () => {
      try {
        const [newEventId] = await toast.promise(proms, {
          pending: t('toast.loading'),
          success: t('toast.success'),
          error: {
            render({ data }) {
              return (
                (data as Error)?.message ??
                `${t('toast.error')} ${payload.customer.name} - ${
                  payload.customer.email
                }`
              );
            },
          },
        });
        return newEventId;
      } finally {
        await bookingsForEvent.refetch();
        await bookingsSummary.refetch();
        setId(uuid());
        setPaymentId(uuid());
        reset(defaultValues);
        await handleClose(!modal.visible);
        setIsLoading(false);
      }
    };
    modal.resolve([callback, payload.eventId]);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [modal.visible, defaultValues, reset]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack
          divider={<Divider />}
          paddingLeft={isSm ? 2 : 6}
          paddingRight={isSm ? 2 : 4}
          gap={4}
        >
          <Box width={isSm ? '100%' : '50%'}>
            <VariantSelect
              experienceId={event.experienceId}
              maxGuestCount={event.slots.total - (event.slots.booked ?? 0)}
              onChange={handleUpdateGuests}
              errorMessage={errors.guests?.message as string | undefined}
              required
            />
            {experience?.customData && (
              <CustomDataInputs
                experience={experience}
                guestCount={totalGuests}
              />
            )}
          </Box>
          <Stack direction={isSm ? 'column' : 'row'} gap={3}>
            <Stack width={isSm ? '100%' : '50%'} gap={2}>
              <CustomerInformation languages={event.languages} />
              <Options />
            </Stack>
            <Box width={isSm ? '100%' : '50%'}>
              <InternalNote />
            </Box>
          </Stack>
        </Stack>
        <Divider sx={{ marginTop: 6.5 }} />
        <CreateBookingFormActions isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export const mapGuestsToOffers = (
  products: Product[],
  guests: { [variantId: string]: number }
): Payment['items'] =>
  Object.fromEntries(
    Object.entries(guests)
      .map(([variantId, count]) => {
        const product = products.find((p) => p.id === variantId);
        if (!product) return [];

        const parent = 'parent' in product ? product.parent : undefined;

        const {
          companyId,
          name,
          experienceId,
          price,
          currency,
          vatRate,
          vatId,
        } = product;

        return [
          [
            variantId,
            {
              count,
              name,
              price,

              offer: {
                sub: variantId,
                parent,
                org: companyId,
                experienceId,
                price: {
                  amount: price,
                  currency,
                  vatRate,
                  vatId,
                },
              },
            },
          ],
        ];
      })
      .flat(1)
  );
