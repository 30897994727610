import { EventSourceType } from '../../../tracking/events/eventProperties';
import { TEventWithTitle } from '../../../Utils/eventHelpers';
import { useAddBookingAction } from './actions/add-booking';
import { useCancelEventAction } from './actions/cancel-event';
import { useEditEventAction } from './actions/edit-event';
import { useMessageParticipantsAction } from './actions/message-participants';
import { usePrintParticipants } from './actions/print-participants';

export const useEventActions = (
  source: EventSourceType,
  event?: TEventWithTitle
) => {
  const { showCancelDialog, isDisabled: isCancellationDisabled } =
    useCancelEventAction(source, event);
  const {
    showMessageParticipantsDialog,
    isDisabled: isMessageParticipantsDisabled,
  } = useMessageParticipantsAction(source, event);
  const { goToEditEvent, isDisabled: isEditEventDisabled } = useEditEventAction(
    source,
    event
  );
  const { showAddBookingDialog, isDisabled: isAddBookingDisabled } =
    useAddBookingAction(source, event);
  const { handlePrintParticipants, isDisabled: isPrintParticipantsDisabled } =
    usePrintParticipants(source, event);

  return {
    showCancelDialog,
    isCancellationDisabled,

    showMessageParticipantsDialog,
    isMessageParticipantsDisabled,

    goToEditEvent,
    isEditEventDisabled,

    showAddBookingDialog,
    isAddBookingDisabled,

    handlePrintParticipants,
    isPrintParticipantsDisabled,
  };
};
