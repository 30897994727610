import { Text } from '@holdbar-com/pixel';
import { FormProvider, useForm } from 'react-hook-form';

import { useTranslate } from '../../../Hooks/useTranslate';
import {
  CompanyPicker,
  CompanyPickerProps,
} from '../../../Pages/Auth/CompanyPicker';
import { DialogWrapper } from './dialog-wrapper';

export type Account = {
  id: string;
  name: string;
  logo?: string;
};

export function ChooseAccountDialog({
  companies,
  onSubmit,
}: {
  companies: Account[];
  onSubmit: CompanyPickerProps['onSubmit'];
}) {
  const form = useForm();
  const { t } = useTranslate('connect.chooseAccount');

  return (
    <DialogWrapper open={true} title={t('title')} fullWidth>
      <Text variant="normal" fontSize="medium">
        {t('description')}
      </Text>
      <FormProvider {...form}>
        <CompanyPicker onSubmit={onSubmit} companies={companies} />
      </FormProvider>
    </DialogWrapper>
  );
}
