import { lightTheme } from '@holdbar-com/pixel';
import { Box, Chip } from '@mui/material';
import { FC } from 'react';

type ConnectedChipProps = {
  label: string;
};

export const ConnectedChip: FC<ConnectedChipProps> = ({ label }) => {
  return (
    <Chip
      icon={
        <Box
          component={'img'}
          width={16}
          height={16}
          sx={{
            objectFit: 'contain',
            objectPosition: 'center',
            color: lightTheme.palette.success.s400,
          }}
          src={'/icons/connect.svg'}
        />
      }
      label={label}
      size="small"
      sx={{
        color: lightTheme.palette.success.s400,
        backgroundColor: lightTheme.palette.success.s100,
        borderRadius: 1,
        paddingX: 0.8,
        paddingY: 1.6,
      }}
    />
  );
};
