import { CheckIn } from '@holdbar-com/utils-types';

import { IBookingsSummary, TBooking } from '../Hooks/useBookings';
import axios from './index';

export const getBookings = async (eventId?: string) => {
  if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  return await fetchAllPages<TBooking>(process.env.REACT_APP_API_BOOKINGS_URL, {
    eventId,
  });
};

export const getBookingsSummary = async (eventId?: string) => {
  if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }
  try {
    const url = `${process.env.REACT_APP_API_BOOKINGS_URL}/event/${
      eventId ? `${eventId}` : ''
    }/summary`;
    const { data } = await axios.get<IBookingsSummary>(url);
    return data;
  } catch {
    throw new Error('Failed to fetch bookings summary');
  }
};

export const getBookingsByDate = async (date: string) => {
  if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  return await fetchAllPages<TBooking>(process.env.REACT_APP_API_BOOKINGS_URL, {
    date,
  });
};

export const getBooking = async (id: string) => {
  if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const { data } = await axios.get<TBooking>(
    `${process.env.REACT_APP_API_BOOKINGS_URL}/${id}`
  );

  return data;
};

export const createBooking = async <T, R = any>(id: string, payload: T) => {
  if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const { data } = await axios.post<R>(
    `${process.env.REACT_APP_API_BOOKINGS_URL}/${id}`,
    { ...payload }
  );

  return data;
};

export const updateBooking = async <T>(id: string, payload: T) => {
  if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  await axios.put(`${process.env.REACT_APP_API_BOOKINGS_URL}/${id}`, {
    ...payload,
  });
};

export const cancelBooking = async (
  id: string,
  shouldRefund: boolean = false
) => {
  if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  await axios.post<unknown>(
    `${process.env.REACT_APP_API_BOOKINGS_URL}/${id}/cancel`,
    { shouldRefund }
  );
};

export const moveBooking = async (id: string, toEventId: string) => {
  if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  const { data } = await axios.post<{
    newBookingId: string;
    newEventId: string;
  }>(`${process.env.REACT_APP_API_BOOKINGS_URL}/${id}/move`, {
    eventId: toEventId,
  });

  return data;
};

export const refundBooking = async (id: string) => {
  if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  await axios.post<unknown>(
    `${process.env.REACT_APP_API_BOOKINGS_URL}/${id}/refund`
  );
};

export const checkInBooking = async (id: string, method: CheckIn['method']) => {
  if (typeof process.env.REACT_APP_API_BOOKINGS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_BOOKINGS_URL is not defined'
    );
  }

  await axios.post<unknown>(
    `${process.env.REACT_APP_API_BOOKINGS_URL}/${id}/check-in`,
    { method }
  );
};

const NEXT_CURSOR_HEADER = 'next-cursor';
const NEXT_CURSOR_QUERY = 'nextCursor';

const fetchAllPages = async <T>(
  baseUrl: string,
  params: Partial<Record<string, string>>
): Promise<T[]> => {
  const { data, headers } = await axios.get<T[]>(baseUrl, { params });

  const nextCursor = headers[NEXT_CURSOR_HEADER];
  if (!nextCursor || nextCursor === params[NEXT_CURSOR_QUERY]) {
    return data;
  }

  return data.concat(
    await fetchAllPages<T>(baseUrl, {
      ...params,
      [NEXT_CURSOR_QUERY]: nextCursor,
    })
  );
};
