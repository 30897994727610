import { Divider, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { createContext } from 'react';
import { useParams } from 'react-router-dom';

import { PageBreadcrumb } from '../../../Components/Page/page_breadcrumb';
import { PageBreadcrumbBreadcrumbsEvent } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/event/PageBreadcrumbBreadcrumbsEvent';
import { PageBreadcrumbBreadcrumbsExperience } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperience';
import { PageBreadcrumbBreadcrumbsExperienceDetails } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperienceDetails';
import { PageBreadcrumbBreadcrumbs } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { useGetBookingsForEvent } from '../../../Hooks/data/useBookings';
import { useGetEvent } from '../../../Hooks/events/useGetEvent';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { TBooking } from '../../../Hooks/useBookings';
import { IExperience, useExperience } from '../../../Hooks/useExperience';
import { ErrorPage } from '../../../Pages/error-page';
import { TEvent } from '../../../Utils/eventHelpers';
import { BookingList } from './BookingList/BookingList';
import { BookingsSummary } from './BookingsSummary';
import { Details } from './Details';
import { Header } from './Header';

type EventDetailsContextType = {
  event?: TEvent;
  experience?: IExperience;
  bookingsForEvent?: TBooking[];
  isLoading?: boolean;
};

export const EventDetailsContext = createContext<EventDetailsContextType>({});

export const EventDetailsView = () => {
  const { id } = useParams<{ id: string }>();

  const { isMd } = useResponsive();

  const {
    event: { data: event, isLoading: isEventLoading, isError, error },
  } = useGetEvent(id ?? '');

  const {
    experience: { data: experience, isLoading: isExperienceLoading },
  } = useExperience(event?.experienceId);

  const {
    bookingsForEvent: {
      data: bookingsForEvent,
      isLoading: isBookingsForEventLoading,
    },
  } = useGetBookingsForEvent(id!);

  const isLoading =
    isEventLoading || isExperienceLoading || isBookingsForEventLoading;

  if (isError)
    return <ErrorPage error={error as AxiosError} resource="event" />;

  return (
    <EventDetailsContext.Provider
      value={{
        event,
        experience,
        bookingsForEvent,
        isLoading,
      }}
    >
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbBreadcrumbsExperience />
        <PageBreadcrumbBreadcrumbsExperienceDetails
          experienceId={event?.experienceId}
        />
        <PageBreadcrumbBreadcrumbsEvent eventId={id} />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <Stack width={'100%'} maxWidth={1200} minWidth={350} gap={4}>
          <Header />
          <Stack gap={2}>
            <Stack direction={isMd ? 'column' : 'row'} gap={isMd ? 0 : 2}>
              <Details />
              {isMd && <Divider />}
              <BookingsSummary />
              {isMd && <Divider />}
            </Stack>
            <BookingList />
          </Stack>
        </Stack>
      </PageBreadcrumb>
    </EventDetailsContext.Provider>
  );
};
