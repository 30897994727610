import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { MediaItem } from '@holdbar-com/utils-types';
import { ArrowUpwardRounded } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import * as api from '../../Api';
import {
  getURLFromMediaItem,
  Media,
} from '../../Components/DraggableGallery/Media';
import { GalleryDropzone } from '../../Components/GalleryDropzone/Dropzone';
import { PhotoIcon } from '../../Icons';
import { ErrorLabel } from '../../Pages/SyiPage/ErrorLabel';
import { useErrors } from '../../Pages/SyiPage/useErrors';
import { TFileTypes } from '../../types';

export const ExperienceSyiSectionPictures = () => {
  const { t } = useTranslation();

  const {
    id: experienceId,
    submitForm,
    isCreateFlow,
  } = useOutletContext<{
    id: string;
    isCreateFlow: boolean;
    submitForm: (forceUpdate: boolean, useModal: boolean) => void;
  }>();

  const { getValues } = useFormContext();

  const { getError, clearError } = useErrors();

  const { field: media } = useController({
    name: 'media.cover',
  });
  const { field: mediaPromises } = useController({ name: 'mediaPromises' });

  const handlePreparedUpload = (
    newMedia: any,
    fileType: TFileTypes,
    uploadFunc: () => Promise<void>
  ) => {
    const prev = getValues()?.media?.cover ?? [];
    const prevProms = getValues()?.mediaPromises ?? [];
    media.onChange([...prev, newMedia]);
    mediaPromises.onChange([...prevProms, uploadFunc]);
    clearError('media', 'cover');
  };

  const handleDeleteUpload = (mediaItem: MediaItem) => {
    const newMedia = (media.value ?? []).filter(
      (el: any, i: number) =>
        getURLFromMediaItem(mediaItem) !== getURLFromMediaItem(el)
    );

    media.onChange(newMedia);
    if (!isCreateFlow) {
      return api.deleteUpload(mediaItem).then(() => submitForm(true, false));
    }
  };

  const hasMedia = useMemo(() => media?.value?.length > 0, [media.value]);

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} mb={3}>
        <Typography variant={'h4'}>
          {t('experience.create.media.title')}
          <ErrorLabel fontSize={'0.88rem'} label={getError('media', 'cover')} />
        </Typography>
      </Stack>

      <Media
        onDelete={handleDeleteUpload}
        onUpdate={media.onChange}
        media={media.value ?? []}
      />

      <GalleryDropzone
        modelType={'experience'}
        fileType={'cover'}
        showProgress={true}
        id={experienceId}
        onPrepared={handlePreparedUpload}
        containerProps={{
          sx: {
            borderRadius: '8px',
            background: 'white',
            border: hasMedia ? 'none' : '1px dashed rgba(100,100,100,0.15)',
            '&:hover': {
              backgroundColor: hasMedia
                ? 'transparent'
                : 'rgba(252,252,252,0.8)',
            },
          },
        }}
      >
        <Box mt={hasMedia ? 2 : 8} mb={hasMedia ? 4 : 8}>
          {!hasMedia && (
            <>
              <PhotoIcon fontSize={'large'} />
              <Typography mt={2} mb={2} variant={'h5'}>
                {t('experience.create.media.uploadTextEmpty')}
              </Typography>
            </>
          )}
          <Stack alignItems={'center'} gap={1}>
            <Button
              size="medium"
              variant={'secondary'}
              leftIcon={<ArrowUpwardRounded fontSize="small" sx={{ mr: 1 }} />}
              style={{ width: 'fit-content' }}
            >
              {t('experience.create.media.uploadButtonLabel')}
            </Button>
            <Text fontSize={'xsmall'} color={lightTheme.palette.neutral.n300}>
              {t('experience.create.media.acceptedFiles')}
            </Text>
          </Stack>
        </Box>
      </GalleryDropzone>

      <Box mt={2}>
        <Typography variant={'h6'}>
          {t('experience.create.media.requirementsTitle')}
        </Typography>
        <Box component={'ul'} pl={2} lineHeight={'2px'}>
          {Array.from(Array(3).keys()).map((el, i) => {
            return (
              <Typography
                key={i}
                mt={1}
                mb={1}
                fontSize={'small'}
                component={'li'}
              >
                {t(`experience.create.pictures.requirement${i + 1}`)}
              </Typography>
            );
          })}
        </Box>
      </Box>
    </>
  );
};
