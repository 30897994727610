import create from 'zustand';
import { persist } from 'zustand/middleware';

export const connectStore = create(
  persist<{
    chooseAccount: () => void;
    accountChosen: boolean;
  }>(
    (set, get) => ({
      chooseAccount: () => set({ accountChosen: true }),
      accountChosen: false,
    }),
    {
      name: 'connect-store',
      getStorage: () => sessionStorage,
    }
  )
);
