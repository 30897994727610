/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull backoffice-web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 4
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/holdbar/Backoffice/implementation/backoffice-web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '0f87928e6a4348c0caf12ce1d309ef06',
  development: '026d89a61f0fd378e700bb9f2625225e'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '4',
    branch: 'main',
    source: 'backoffice-web',
    versionId: '8fea32b5-7cd3-4803-a56c-34686f24bb52'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "# Active Experiences Listed"?: number;
  /**
   * The amount of bookings on the booking page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "# Bookings"?: number;
  /**
   * The count of cancelled bookings listed on the bookings page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "# Cancelled Bookings"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "# Days Since First XP Created"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "# Days Since Last XP Created"?: number;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "# Days To Upcoming Booking"?: number;
  /**
   * The count of different experiences featured on the booking page (regardless of paid or unpaid status)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "# Experiences Booked"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "# Experiences Listed"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "# Inactive Experiences Listed"?: number;
  /**
   * The count of paid bookings listed on the bookings page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "# Paid Bookings"?: number;
  /**
   * The count of unpaid bookings listed on the bookings page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "# Unpaid Bookings"?: number;
  /**
   * Whether the company has enabled Booking Reminders
   */
  "Booking Reminder Enabled"?: boolean;
  /**
   * Time before event (in hours) when booking reminder is set to be sent for the Booking Reminder Storefront feature.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Booking Reminder Time"?: number;
  "Company Id"?: string;
  "Company Name"?: string;
  /**
   * Determine whether or not a user is identified as a Holdbar employee/user
   */
  "Is Internal"?: boolean;
  report_type?: string;
  Role?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_number_guests?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_sales?: number;
}

export interface AmplitudePageViewedProperties {
  "[Amplitude] Page Domain"?: string;
  "[Amplitude] Page Location"?: string;
  "[Amplitude] Page Path"?: string;
  "[Amplitude] Page Title"?: string;
  "[Amplitude] Page URL"?: string;
  fbclid?: string;
  gclid?: string;
  referrer?: string;
  referring_domain?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  wbraid?: string;
}

export interface BookingCheckInCancelledProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount?: number;
  booking_channel?: string;
  booking_date?: string;
  booking_id?: string;
  booking_source?: string;
  check_in_method: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking?: number;
  event_date?: string;
  event_id?: string;
  experience_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name?: string;
  language?: string;
  marketing_consent?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
}

export interface BookingCheckInCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  check_in_method: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
}

export interface BookingCheckInStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount?: number;
  booking_channel?: string;
  booking_date?: string;
  booking_id?: string;
  booking_source?: string;
  check_in_method: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking?: number;
  event_date?: string;
  event_id?: string;
  experience_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name?: string;
  language?: string;
  marketing_consent?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
}

export interface BookingDetailsPageOpenedProperties {
  booking_id: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
}

export interface BookingGuestEmailCopiedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
}

export interface BookingGuestEmailStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
}

export interface BookingInternalNoteCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
  text_content: string;
}

export interface BookingInternalNoteStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
}

export interface BookingMoveCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
  /**
   * The page/component where the event originates from
   */
  source: string;
}

export interface BookingMoveStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
  /**
   * The page/component where the event originates from
   */
  source: string;
}

export interface BookingRefundCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
  /**
   * The page/component where the event originates from
   */
  source: string;
}

export interface BookingRefundExitedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
  /**
   * The page/component where the event originates from
   */
  source: string;
}

export interface BookingRefundStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking?: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language?: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * The page/component where the event originates from
   */
  source?: string;
}

export interface BookingReminderTbeDropdownFilterAppliedProperties {
  /**
   * Time before event in hours
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  booking_reminder_tbe: number;
}

export interface BookingResendNotificationFlowCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_since_confirmation_sent?: number;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
}

export interface BookingResendNotificationFlowExitedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_since_confirmation_sent?: number;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
}

export interface BookingResendNotificationFlowStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  booking_amount: number;
  booking_channel: string;
  booking_date: string;
  booking_id: string;
  booking_source: string;
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_since_confirmation_sent?: number;
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking: number;
  event_date: string;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  language: string;
  marketing_consent: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
}

export interface BookingsPageNextDayLoadedProperties {
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking?: number;
  /**
   * The count of cancelled bookings listed on the bookings page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_cancelled_bookings: number;
  /**
   * The amount of bookings on the booking page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_of_bookings?: number;
  /**
   * The count of different experiences featured in the booking page (regardless of paid or unpaid status)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_of_experiences_booked: number;
  /**
   * The count of paid bookings listed on the bookings page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_of_paid_bookings: number;
  /**
   * The count of unpaid bookings listed on the bookings page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_unpaid_bookings: number;
}

export interface BookingsPageViewedProperties {
  /**
   * Days until the upcoming booking
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  days_until_upcoming_booking?: number;
  /**
   * The count of cancelled bookings listed on the bookings page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_cancelled_bookings: number;
  /**
   * The amount of bookings on the booking page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_of_bookings?: number;
  /**
   * The count of different experiences featured in the booking page (regardless of paid or unpaid status)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_of_experiences_booked: number;
  /**
   * The count of paid bookings listed on the bookings page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_of_paid_bookings: number;
  /**
   * The count of unpaid bookings listed on the bookings page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_unpaid_bookings: number;
}

export interface ButtonClickProperties {
  location: string;
  name: string;
  page_title?: string;
}

export interface CalendarBookingStatusFilterAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  status_filter_list: string[];
}

export interface CalendarEventStatusFilterAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  status_filter_list: string[];
}

export interface CalendarExperienceFilterAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_experiences: number;
}

export interface CalendarGuideFilterAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
}

export interface CalendarLanguageFilterAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
}

export interface CalendarLocationFilterAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_locations: number;
}

export interface CalendarPageViewedProperties {
  /**
   * The date view selected ("saved" setting)
   */
  date_view_saved?: string;
  /**
   * Whether the group by experience toggle is selected or not ("saved" setting)
   */
  group_by_saved?: boolean;
  /**
   * List of filters applied ("saved" setting)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  list_of_filters_saved?: string[];
  /**
   * The amount of bookings on the booking page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  number_of_bookings?: number;
  /**
   * The count of filters that have been applied ("saved" setting)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_filters_saved?: number;
  /**
   * The page/component where the event originates from
   */
  source?: string;
}

export interface CalendarStatusFilterAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  status_filter_list: string[];
}

export interface CalendarViewGroupDeselectedProperties {
  view: string;
}

export interface CalendarViewGroupSelectedProperties {
  view: string;
}

export interface CalendarViewPickerDateSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_from_current_date?: number;
}

export interface EditCapacitySaveProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  newCapacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  previousCapacity: number;
  /**
   * What action / button was used to trigger a given event, ie. can be a 'button' or 'enter-key'
   */
  trigger: string;
}

export interface EditorChangeValueProperties {
  editorKey: string;
}

export interface EventBookingAddedStartedProperties {
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_to_booking_closing: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_until_event: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  location: string;
  /**
   * The number of add-ons the event has (lunch, etc), if null then it is 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_addons?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * The number of variants the event has (ticket types), if null then it is 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_variants?: number;
  /**
   * The page/component where the event originates from
   */
  source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_booking_value?: number;
}

export interface EventCancellationStartedProperties {
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_to_booking_closing: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_until_event: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  location: string;
  /**
   * The number of add-ons the event has (lunch, etc), if null then it is 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_addons?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * The number of variants the event has (ticket types), if null then it is 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_variants?: number;
  /**
   * The page/component where the event originates from
   */
  source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_booking_value?: number;
}

export interface EventDetailsPopupViewedProperties {
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_to_booking_closing: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_until_event: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  location: string;
  /**
   * The number of add-ons the event has (lunch, etc), if null then it is 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_addons?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * The number of variants the event has (ticket types), if null then it is 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_variants?: number;
  /**
   * The page/component where the event originates from
   */
  source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_booking_value?: number;
}

export interface EventDetailsViewedProperties {
  /**
   * The page/component where the event originates from
   */
  source?: string;
}

export interface EventEditStartedProperties {
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_to_booking_closing: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_until_event: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  location: string;
  /**
   * The number of add-ons the event has (lunch, etc), if null then it is 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_addons?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * The number of variants the event has (ticket types), if null then it is 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_variants?: number;
  /**
   * The page/component where the event originates from
   */
  source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_booking_value?: number;
}

export interface EventFlowAddTimeClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_times: number;
}

export interface EventFlowBookingStepCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  flow_type: string;
  is_private: boolean;
  language?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  location: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides?: number;
}

export interface EventFlowDetailsStepCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  flow_type: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  location: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
}

export interface EventFlowEndDateSelectedProperties {
  date: string;
}

export interface EventFlowEndTimeSelectedProperties {
  date: string;
}

export interface EventFlowExperienceSelectedProperties {
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
}

export interface EventFlowGoToPreviousStepProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_event_closes?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_event_starts: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  event_scheduled_date: string;
  experience_id: string;
  flow_type: string;
  is_private: boolean;
  is_recurring?: string;
  language?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  location: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_times: number;
}

export interface EventFlowGuideSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
}

export interface EventFlowLanguageSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
}

export interface EventFlowLocationSelectedProperties {
  location: string;
}

export interface EventFlowOpenedProperties {
  flow_type: string;
  /**
   * The page/component where the event originates from
   */
  source?: string;
}

export interface EventFlowRecurringDateSelectedProperties {
  date: string;
}

export interface EventFlowRecurringTypeSelectedProperties {
  is_recurring?: string;
}

export interface EventFlowSeatsSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
}

export interface EventFlowStartDateSelectedProperties {
  date: string;
}

export interface EventFlowStartTimeSelectedProperties {
  date: string;
}

export interface EventFlowTimeStepCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_to_booking_closing: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_event_closes?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_event_starts: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  flow_type: string;
  is_private: boolean;
  is_recurring?: string;
  language?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  location: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_times: number;
}

export interface EventMessageAllStartedProperties {
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_to_booking_closing: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_until_event: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  location: string;
  /**
   * The number of add-ons the event has (lunch, etc), if null then it is 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_addons?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * The number of variants the event has (ticket types), if null then it is 0
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_variants?: number;
  /**
   * The page/component where the event originates from
   */
  source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total_booking_value?: number;
}

export interface EventButtonClickedProperties {
  action: string;
  location: string;
}

export interface ExperienceActivatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
}

export interface ExperienceBookingFlowLinkCopiedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
}

export interface ExperienceBookingFlowLinkOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
}

export interface ExperienceDeactivatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
}

export interface ExperienceDetailsPageOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
}

export interface ExperienceEventActivatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
}

export interface ExperienceEventCancellationCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_cancelled_with_refund: boolean;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  text_content: string;
}

export interface ExperienceEventCancellationExitedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
}

export interface ExperienceEventCancellationStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
}

export interface ExperienceEventCreationStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
}

export interface ExperienceEventDeactivatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
}

export interface ExperienceEventDetailsOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
}

export interface ExperienceEventDetailsThreeDotsClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
}

export interface ExperienceEventEditStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  event_id: string;
  event_scheduled_date: string;
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
}

export interface ExperienceEventStateFilterOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
  state_selected?: string;
}

export interface ExperienceEventStateFilterUpdatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
  state_selected?: string;
}

export interface ExperienceEventStatusFilterOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  status_selected?: string;
}

export interface ExperienceEventStatusFilterUpdatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  status_selected?: string;
}

export interface ExperienceGoToPaginationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  page_number: number;
}

export interface ExperienceLocationFilterOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
  selected_location_id?: string;
}

export interface ExperienceLocationFilterUpdatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
  selected_location_id?: string;
}

export interface ExperienceThreeDotsOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
}

export interface ExperiencesPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_since_most_recent_xp_creation?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_since_oldest_xp_creation?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  list_of_experience_ids: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  list_of_experiences: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_experiences: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_experiences: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_inactive_experiences: number;
  /**
   * The page/component where the event originates from
   */
  source?: string;
}

export interface GenerateDescriptionAddKeywordProperties {
  fromSuggestion: boolean;
  keyword: string;
}

export interface GenerateDescriptionFailedProperties {
  experienceId: string;
  experienceName: string;
}

export interface GenerateDescriptionGenerateProperties {
  experienceId: string;
  experienceName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  numberOfKeywords: number;
}

export interface GenerateDescriptionRemoveKeywordProperties {
  keyword: string;
}

export interface GoBackCalendarViewChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_from_current_date?: number;
  view: string;
}

export interface GoForwardCalendarViewChangedProperties {
  view: string;
}

export interface GuestListOpenedProperties {
  /**
   * The page/component where the event originates from
   */
  source?: string;
}

export interface OverviewExperienceFilterDeselectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  list_of_experience_ids: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  list_of_experiences: string[];
}

export interface OverviewExperienceFilterOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_experiences: number;
}

export interface OverviewExperienceFilterSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  list_of_experience_ids: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  list_of_experiences: string[];
  selected_experience: string;
}

export interface OverviewPageViewedProperties {
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_from_current_date?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_gift_cards_sold?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_number_customers?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_number_guests?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_sales?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_views_experiences?: number;
}

export interface OverviewReportFirstDateSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_from_current_date?: number;
}

export interface OverviewReportSecondDateSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_from_current_date?: number;
}

export interface OverviewRequestReportCompletedProperties {
  report_type: string;
}

export interface OverviewRequestReportStartedProperties {
  currency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  days_from_current_date?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_gift_cards_sold?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_number_customers?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_number_guests?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_sales?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_views_experiences?: number;
}

export interface OverviewRequestReportTypeSelectedProperties {
  report_type: string;
}

export interface QuickSetupCloseDialogOpenedProperties {
  current_step: string;
}

export interface QuickSetupDescriptionStepCompletedProperties {
  currency?: string;
  domain?: string;
  event_scheduled_date?: string;
  experience_description?: string;
  experience_id?: string;
  experience_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  media_length: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  price?: number;
  ticket_name?: string;
}

export interface QuickSetupDraftSiteEditDetailsClickedProperties {
  experience_id: string;
}

export interface QuickSetupDraftSiteGoToExperienceClickedProperties {
  experience_id: string;
}

export interface QuickSetupDraftSiteSuccessProperties {
  storefront_url: string;
}

export interface QuickSetupLocationStepCompletedProperties {
  currency?: string;
  domain?: string;
  event_scheduled_date?: string;
  experience_description?: string;
  experience_id?: string;
  experience_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  media_length: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  price?: number;
  ticket_name?: string;
}

export interface QuickSetupMediaStepCompletedProperties {
  currency?: string;
  domain?: string;
  event_scheduled_date?: string;
  experience_description?: string;
  experience_id?: string;
  experience_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  media_length: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  price?: number;
  ticket_name?: string;
}

export interface QuickSetupMediaStepStockPhotoSelectedProperties {
  image_url: string;
}

export interface QuickSetupNameStepCompletedProperties {
  currency?: string;
  domain?: string;
  event_scheduled_date?: string;
  experience_description?: string;
  experience_id?: string;
  experience_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  media_length: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  price?: number;
  ticket_name?: string;
}

export interface QuickSetupNextStepClickedProperties {
  current_step: string;
  next_step: string;
}

export interface QuickSetupOpenPreviewClickedProperties {
  url: string;
}

export interface QuickSetupOpenedProperties {
  /**
   * The page/component where the event originates from
   */
  source?: string;
}

export interface QuickSetupPreviousStepClickedProperties {
  current_step: string;
  previous_step: string;
}

export interface QuickSetupPriceStepCompletedProperties {
  currency?: string;
  domain?: string;
  event_scheduled_date?: string;
  experience_description?: string;
  experience_id?: string;
  experience_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  media_length: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  price?: number;
  ticket_name?: string;
}

export interface QuickSetupSkipStepClickedProperties {
  current_step: string;
  next_step: string;
}

export interface QuickSetupStartStepCompletedProperties {
  currency?: string;
  domain?: string;
  event_scheduled_date?: string;
  experience_description?: string;
  experience_id?: string;
  experience_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  media_length: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  price?: number;
  ticket_name?: string;
}

export interface QuickSetupTimeStepCompletedProperties {
  currency?: string;
  domain?: string;
  event_scheduled_date?: string;
  experience_description?: string;
  experience_id?: string;
  experience_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  media_length: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  price?: number;
  ticket_name?: string;
}

export interface QuickSetupTimeStepFromDateSelectedProperties {
  event_start_date: string;
}

export interface QuickSetupTimeStepFromTimeSelectedProperties {
  event_start_date: string;
}

export interface QuickSetupTimeStepToDateSelectedProperties {
  event_end_date: string;
}

export interface QuickSetupTimeStepToTimeSelectedProperties {
  event_end_date: string;
}

export interface SearchOpenProperties {
  location: string;
  /**
   * What action / button was used to trigger a given event, ie. can be a 'button' or 'enter-key'
   */
  trigger: string;
}

export interface SearchSearchedProperties {
  query: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  results: number;
}

export interface SearchSelectResultProperties {
  location: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  results: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  selectedIndex: number;
}

export interface SearchUserCloseDialogProperties {
  location: string;
}

export interface SeeExperienceOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_until_first_event_starts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  event_capacity: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  event_dates_array: string[];
  experience_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experience_name: string;
  is_private: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  language_list: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_active_events: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guests: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_guides: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_upcoming_events: number;
}

export interface SignUpConsentChangedProperties {
  consent: boolean;
}

export interface SignUpFlowCompletedProperties {
  is_invite: boolean;
  user_id: string;
}

export interface StorefrontImplementationExperienceSelectedProperties {
  selected_experience: string;
}

export interface StorefrontImplementationFlowTypeChangedProperties {
  selected_flow_type: string;
}

export interface StorefrontImplementationLanguageSelectedProperties {
  language?: string;
}

export interface StorefrontImplementationLinkCopyButtonClickedProperties {
  integration_link?: string;
}

export interface StorefrontImplementationLinkKeyboardCopiedProperties {
  integration_link?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudePageViewed implements BaseEvent {
  event_type = '[Amplitude] Page Viewed';

  constructor(
    public event_properties?: AmplitudePageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingCheckInCancelled implements BaseEvent {
  event_type = 'Booking Check In Cancelled';

  constructor(
    public event_properties: BookingCheckInCancelledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingCheckInCompleted implements BaseEvent {
  event_type = 'Booking Check In Completed';

  constructor(
    public event_properties: BookingCheckInCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingCheckInStarted implements BaseEvent {
  event_type = 'Booking Check In Started';

  constructor(
    public event_properties: BookingCheckInStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingDetailsPageOpened implements BaseEvent {
  event_type = 'Booking Details Page Opened';

  constructor(
    public event_properties: BookingDetailsPageOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingGuestEmailCopied implements BaseEvent {
  event_type = 'Booking Guest Email Copied';

  constructor(
    public event_properties: BookingGuestEmailCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingGuestEmailStarted implements BaseEvent {
  event_type = 'Booking Guest Email Started';

  constructor(
    public event_properties: BookingGuestEmailStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingInternalNoteCompleted implements BaseEvent {
  event_type = 'Booking Internal Note Completed';

  constructor(
    public event_properties: BookingInternalNoteCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingInternalNoteStarted implements BaseEvent {
  event_type = 'Booking Internal Note Started';

  constructor(
    public event_properties: BookingInternalNoteStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingMoveCompleted implements BaseEvent {
  event_type = 'Booking Move Completed';

  constructor(
    public event_properties: BookingMoveCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingMoveStarted implements BaseEvent {
  event_type = 'Booking Move Started';

  constructor(
    public event_properties: BookingMoveStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingRefundCompleted implements BaseEvent {
  event_type = 'Booking Refund Completed';

  constructor(
    public event_properties: BookingRefundCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingRefundExited implements BaseEvent {
  event_type = 'Booking Refund Exited';

  constructor(
    public event_properties: BookingRefundExitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingRefundStarted implements BaseEvent {
  event_type = 'Booking Refund Started';

  constructor(
    public event_properties: BookingRefundStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingReminderTbeDropdownFilterApplied implements BaseEvent {
  event_type = 'Booking Reminder TBE Dropdown Filter Applied';

  constructor(
    public event_properties: BookingReminderTbeDropdownFilterAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingReminderTbeDropdownFilterOpened implements BaseEvent {
  event_type = 'Booking Reminder TBE Dropdown Filter Opened';
}

export class BookingReminderToggledOff implements BaseEvent {
  event_type = 'Booking Reminder Toggled Off';
}

export class BookingReminderToggledOn implements BaseEvent {
  event_type = 'Booking Reminder Toggled On';
}

export class BookingResendNotificationFlowCompleted implements BaseEvent {
  event_type = 'Booking Resend Notification Flow Completed';

  constructor(
    public event_properties: BookingResendNotificationFlowCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingResendNotificationFlowExited implements BaseEvent {
  event_type = 'Booking Resend Notification Flow Exited';

  constructor(
    public event_properties: BookingResendNotificationFlowExitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingResendNotificationFlowStarted implements BaseEvent {
  event_type = 'Booking Resend Notification Flow Started';

  constructor(
    public event_properties: BookingResendNotificationFlowStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingsPageNextDayLoaded implements BaseEvent {
  event_type = 'Bookings Page Next Day Loaded';

  constructor(
    public event_properties: BookingsPageNextDayLoadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookingsPageViewed implements BaseEvent {
  event_type = 'Bookings Page Viewed';

  constructor(
    public event_properties: BookingsPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ButtonClick implements BaseEvent {
  event_type = 'button.click';

  constructor(
    public event_properties: ButtonClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarBookingStatusFilterApplied implements BaseEvent {
  event_type = 'Calendar Booking Status Filter Applied';

  constructor(
    public event_properties: CalendarBookingStatusFilterAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarEventStatusFilterApplied implements BaseEvent {
  event_type = 'Calendar Event Status Filter Applied';

  constructor(
    public event_properties: CalendarEventStatusFilterAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarExperienceFilterApplied implements BaseEvent {
  event_type = 'Calendar Experience Filter Applied';

  constructor(
    public event_properties: CalendarExperienceFilterAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarGuideFilterApplied implements BaseEvent {
  event_type = 'Calendar Guide Filter Applied';

  constructor(
    public event_properties: CalendarGuideFilterAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarLanguageFilterApplied implements BaseEvent {
  event_type = 'Calendar Language Filter Applied';

  constructor(
    public event_properties: CalendarLanguageFilterAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarLocationFilterApplied implements BaseEvent {
  event_type = 'Calendar Location Filter Applied';

  constructor(
    public event_properties: CalendarLocationFilterAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarPageViewed implements BaseEvent {
  event_type = 'Calendar Page Viewed';

  constructor(
    public event_properties?: CalendarPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarStatusFilterApplied implements BaseEvent {
  event_type = 'Calendar Status Filter Applied';

  constructor(
    public event_properties: CalendarStatusFilterAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarViewDatePickerOpened implements BaseEvent {
  event_type = 'Calendar View Date Picker Opened';
}

export class CalendarViewGroupDeselected implements BaseEvent {
  event_type = 'Calendar View Group Deselected';

  constructor(
    public event_properties: CalendarViewGroupDeselectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarViewGroupSelected implements BaseEvent {
  event_type = 'Calendar View Group Selected';

  constructor(
    public event_properties: CalendarViewGroupSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CalendarViewPickerDateSelected implements BaseEvent {
  event_type = 'Calendar View Picker Date Selected';

  constructor(
    public event_properties?: CalendarViewPickerDateSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditCapacityClose implements BaseEvent {
  event_type = 'editCapacity.close';
}

export class EditCapacityOpen implements BaseEvent {
  event_type = 'editCapacity.open';
}

export class EditCapacitySave implements BaseEvent {
  event_type = 'editCapacity.save';

  constructor(
    public event_properties: EditCapacitySaveProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditorChangeValue implements BaseEvent {
  event_type = 'editor.changeValue';

  constructor(
    public event_properties: EditorChangeValueProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventBookingAddedStarted implements BaseEvent {
  event_type = 'Event Booking Added Started';

  constructor(
    public event_properties: EventBookingAddedStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventCancellationStarted implements BaseEvent {
  event_type = 'Event Cancellation Started';

  constructor(
    public event_properties: EventCancellationStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventDetailsPopupViewed implements BaseEvent {
  event_type = 'Event Details Popup Viewed';

  constructor(
    public event_properties: EventDetailsPopupViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventDetailsViewed implements BaseEvent {
  event_type = 'Event Details Viewed';

  constructor(
    public event_properties?: EventDetailsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventEditStarted implements BaseEvent {
  event_type = 'Event Edit Started';

  constructor(
    public event_properties: EventEditStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowAddTimeClicked implements BaseEvent {
  event_type = 'Event Flow Add Time Clicked';

  constructor(
    public event_properties: EventFlowAddTimeClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowBookingStepCompleted implements BaseEvent {
  event_type = 'Event Flow Booking Step Completed';

  constructor(
    public event_properties: EventFlowBookingStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowDetailsStepCompleted implements BaseEvent {
  event_type = 'Event Flow Details Step Completed';

  constructor(
    public event_properties: EventFlowDetailsStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowEndDateOpened implements BaseEvent {
  event_type = 'Event Flow End Date Opened';
}

export class EventFlowEndDateSelected implements BaseEvent {
  event_type = 'Event Flow End Date Selected';

  constructor(
    public event_properties: EventFlowEndDateSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowEndTimeOpened implements BaseEvent {
  event_type = 'Event Flow End Time Opened';
}

export class EventFlowEndTimeSelected implements BaseEvent {
  event_type = 'Event Flow End Time Selected';

  constructor(
    public event_properties: EventFlowEndTimeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowExperienceSelected implements BaseEvent {
  event_type = 'Event Flow Experience Selected';

  constructor(
    public event_properties: EventFlowExperienceSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowExperienceSelectionOpened implements BaseEvent {
  event_type = 'Event Flow Experience Selection Opened';
}

export class EventFlowGoToPreviousStep implements BaseEvent {
  event_type = 'Event Flow Go To Previous Step';

  constructor(
    public event_properties: EventFlowGoToPreviousStepProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowGuideSelected implements BaseEvent {
  event_type = 'Event Flow Guide Selected';

  constructor(
    public event_properties: EventFlowGuideSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowGuideSelectionOpened implements BaseEvent {
  event_type = 'Event Flow Guide Selection Opened';
}

export class EventFlowLanguageSelected implements BaseEvent {
  event_type = 'Event Flow Language Selected';

  constructor(
    public event_properties: EventFlowLanguageSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowLanguageSelectionOpened implements BaseEvent {
  event_type = 'Event Flow Language Selection Opened';
}

export class EventFlowLocationSelected implements BaseEvent {
  event_type = 'Event Flow Location Selected';

  constructor(
    public event_properties: EventFlowLocationSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowLocationSelectionOpened implements BaseEvent {
  event_type = 'Event Flow Location Selection Opened';
}

export class EventFlowOpened implements BaseEvent {
  event_type = 'Event Flow Opened';

  constructor(
    public event_properties: EventFlowOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowPrivateStatusSelected implements BaseEvent {
  event_type = 'Event Flow Private Status Selected';
}

export class EventFlowRecurringDateOpened implements BaseEvent {
  event_type = 'Event Flow Recurring Date Opened';
}

export class EventFlowRecurringDateSelected implements BaseEvent {
  event_type = 'Event Flow Recurring Date Selected';

  constructor(
    public event_properties: EventFlowRecurringDateSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowRecurringTypeOpened implements BaseEvent {
  event_type = 'Event Flow Recurring Type Opened';
}

export class EventFlowRecurringTypeSelected implements BaseEvent {
  event_type = 'Event Flow Recurring Type Selected';

  constructor(
    public event_properties?: EventFlowRecurringTypeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowSeatsSelected implements BaseEvent {
  event_type = 'Event Flow Seats Selected';

  constructor(
    public event_properties: EventFlowSeatsSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowSeatsSelectionOpened implements BaseEvent {
  event_type = 'Event Flow Seats Selection Opened';
}

export class EventFlowStartDateOpened implements BaseEvent {
  event_type = 'Event Flow Start Date Opened';
}

export class EventFlowStartDateSelected implements BaseEvent {
  event_type = 'Event Flow Start Date Selected';

  constructor(
    public event_properties: EventFlowStartDateSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowStartTimeOpened implements BaseEvent {
  event_type = 'Event Flow Start Time Opened';
}

export class EventFlowStartTimeSelected implements BaseEvent {
  event_type = 'Event Flow Start Time Selected';

  constructor(
    public event_properties: EventFlowStartTimeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventFlowTimeStepCompleted implements BaseEvent {
  event_type = 'Event Flow Time Step Completed';

  constructor(
    public event_properties: EventFlowTimeStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventMessageAllStarted implements BaseEvent {
  event_type = 'Event Message All Started';

  constructor(
    public event_properties: EventMessageAllStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EventButtonClicked implements BaseEvent {
  event_type = 'event.buttonClicked';

  constructor(
    public event_properties: EventButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceActivated implements BaseEvent {
  event_type = 'Experience Activated';

  constructor(
    public event_properties: ExperienceActivatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceBookingFlowLinkCopied implements BaseEvent {
  event_type = 'Experience Booking Flow Link Copied';

  constructor(
    public event_properties: ExperienceBookingFlowLinkCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceBookingFlowLinkOpened implements BaseEvent {
  event_type = 'Experience Booking Flow Link Opened';

  constructor(
    public event_properties: ExperienceBookingFlowLinkOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceDeactivated implements BaseEvent {
  event_type = 'Experience Deactivated';

  constructor(
    public event_properties: ExperienceDeactivatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceDetailsPageOpened implements BaseEvent {
  event_type = 'Experience Details Page Opened';

  constructor(
    public event_properties: ExperienceDetailsPageOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventActivated implements BaseEvent {
  event_type = 'Experience Event Activated';

  constructor(
    public event_properties: ExperienceEventActivatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventCancellationCompleted implements BaseEvent {
  event_type = 'Experience Event Cancellation Completed';

  constructor(
    public event_properties: ExperienceEventCancellationCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventCancellationExited implements BaseEvent {
  event_type = 'Experience Event Cancellation Exited';

  constructor(
    public event_properties: ExperienceEventCancellationExitedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventCancellationStarted implements BaseEvent {
  event_type = 'Experience Event Cancellation Started';

  constructor(
    public event_properties: ExperienceEventCancellationStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventCreationStarted implements BaseEvent {
  event_type = 'Experience Event Creation Started';

  constructor(
    public event_properties: ExperienceEventCreationStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventDeactivated implements BaseEvent {
  event_type = 'Experience Event Deactivated';

  constructor(
    public event_properties: ExperienceEventDeactivatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventDetailsOpened implements BaseEvent {
  event_type = 'Experience Event Details Opened';

  constructor(
    public event_properties: ExperienceEventDetailsOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventDetailsThreeDotsClicked implements BaseEvent {
  event_type = 'Experience Event Details Three Dots Clicked';

  constructor(
    public event_properties: ExperienceEventDetailsThreeDotsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventEditStarted implements BaseEvent {
  event_type = 'Experience Event Edit Started';

  constructor(
    public event_properties: ExperienceEventEditStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventStateFilterOpened implements BaseEvent {
  event_type = 'Experience Event State Filter Opened';

  constructor(
    public event_properties: ExperienceEventStateFilterOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventStateFilterUpdated implements BaseEvent {
  event_type = 'Experience Event State Filter Updated';

  constructor(
    public event_properties: ExperienceEventStateFilterUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventStatusFilterOpened implements BaseEvent {
  event_type = 'Experience Event Status Filter Opened';

  constructor(
    public event_properties: ExperienceEventStatusFilterOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceEventStatusFilterUpdated implements BaseEvent {
  event_type = 'Experience Event Status Filter Updated';

  constructor(
    public event_properties: ExperienceEventStatusFilterUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceGoToPagination implements BaseEvent {
  event_type = 'Experience Go To Pagination';

  constructor(
    public event_properties: ExperienceGoToPaginationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceLocationFilterOpened implements BaseEvent {
  event_type = 'Experience Location Filter Opened';

  constructor(
    public event_properties: ExperienceLocationFilterOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceLocationFilterUpdated implements BaseEvent {
  event_type = 'Experience Location Filter Updated';

  constructor(
    public event_properties: ExperienceLocationFilterUpdatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperienceThreeDotsOpened implements BaseEvent {
  event_type = 'Experience Three Dots Opened';

  constructor(
    public event_properties: ExperienceThreeDotsOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExperiencesPageViewed implements BaseEvent {
  event_type = 'Experiences Page Viewed';

  constructor(
    public event_properties: ExperiencesPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GenerateDescriptionAddKeyword implements BaseEvent {
  event_type = 'generateDescription.addKeyword';

  constructor(
    public event_properties: GenerateDescriptionAddKeywordProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GenerateDescriptionClose implements BaseEvent {
  event_type = 'generateDescription.close';
}

export class GenerateDescriptionFailed implements BaseEvent {
  event_type = 'generateDescription.failed';

  constructor(
    public event_properties: GenerateDescriptionFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GenerateDescriptionGenerate implements BaseEvent {
  event_type = 'generateDescription.generate';

  constructor(
    public event_properties: GenerateDescriptionGenerateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GenerateDescriptionOpen implements BaseEvent {
  event_type = 'generateDescription.open';
}

export class GenerateDescriptionRemoveKeyword implements BaseEvent {
  event_type = 'generateDescription.removeKeyword';

  constructor(
    public event_properties: GenerateDescriptionRemoveKeywordProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GoBackCalendarViewChanged implements BaseEvent {
  event_type = 'Go Back Calendar View Changed';

  constructor(
    public event_properties: GoBackCalendarViewChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GoForwardCalendarViewChanged implements BaseEvent {
  event_type = 'Go Forward Calendar View Changed';

  constructor(
    public event_properties: GoForwardCalendarViewChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GoToDayCalendarViewChanged implements BaseEvent {
  event_type = 'Go To Day Calendar View Changed';
}

export class GoToMonthCalendarViewChanged implements BaseEvent {
  event_type = 'Go To Month Calendar View Changed';
}

export class GoToWeekCalendarViewChanged implements BaseEvent {
  event_type = 'Go To Week Calendar View Changed';
}

export class GuestListOpened implements BaseEvent {
  event_type = 'Guest List Opened';

  constructor(
    public event_properties?: GuestListOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntercomPopupClosed implements BaseEvent {
  event_type = 'Intercom Popup Closed';
}

export class IntercomPopupOpened implements BaseEvent {
  event_type = 'Intercom Popup Opened';
}

export class OnboardingListDoneButtonClicked implements BaseEvent {
  event_type = 'Onboarding List Done Button Clicked';
}

export class OnboardingListEventBulletClicked implements BaseEvent {
  event_type = 'Onboarding List Event Bullet Clicked';
}

export class OnboardingListEventClicked implements BaseEvent {
  event_type = 'Onboarding List Event Clicked';
}

export class OnboardingListExperienceBulletClicked implements BaseEvent {
  event_type = 'Onboarding List Experience Bullet Clicked';
}

export class OnboardingListExperienceClicked implements BaseEvent {
  event_type = 'Onboarding List Experience Clicked';
}

export class OnboardingListImplementationBulletClicked implements BaseEvent {
  event_type = 'Onboarding List Implementation Bullet Clicked';
}

export class OnboardingListImplementationClicked implements BaseEvent {
  event_type = 'Onboarding List Implementation Clicked';
}

export class OnboardingListPaymentBulletClicked implements BaseEvent {
  event_type = 'Onboarding List Payment Bullet Clicked';
}

export class OnboardingListPaymentClicked implements BaseEvent {
  event_type = 'Onboarding List Payment Clicked';
}

export class OverviewExperienceFilterDeselected implements BaseEvent {
  event_type = 'Overview Experience Filter Deselected';

  constructor(
    public event_properties: OverviewExperienceFilterDeselectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OverviewExperienceFilterExited implements BaseEvent {
  event_type = 'Overview Experience Filter Exited';
}

export class OverviewExperienceFilterOpened implements BaseEvent {
  event_type = 'Overview Experience Filter Opened';

  constructor(
    public event_properties: OverviewExperienceFilterOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OverviewExperienceFilterSelected implements BaseEvent {
  event_type = 'Overview Experience Filter Selected';

  constructor(
    public event_properties: OverviewExperienceFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OverviewExperienceFilterStarted implements BaseEvent {
  event_type = 'Overview Experience Filter Started';
}

export class OverviewMyHoldbarPageClicked implements BaseEvent {
  event_type = 'Overview My Holdbar Page Clicked';
}

export class OverviewPageViewed implements BaseEvent {
  event_type = 'Overview Page Viewed';

  constructor(
    public event_properties?: OverviewPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OverviewReportFirstDateOpened implements BaseEvent {
  event_type = 'Overview Report First Date Opened';
}

export class OverviewReportFirstDateSelected implements BaseEvent {
  event_type = 'Overview Report First Date Selected';

  constructor(
    public event_properties?: OverviewReportFirstDateSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OverviewReportSecondDateOpened implements BaseEvent {
  event_type = 'Overview Report Second Date Opened';
}

export class OverviewReportSecondDateSelected implements BaseEvent {
  event_type = 'Overview Report Second Date Selected';

  constructor(
    public event_properties?: OverviewReportSecondDateSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OverviewRequestReportCompleted implements BaseEvent {
  event_type = 'Overview Request Report Completed';

  constructor(
    public event_properties: OverviewRequestReportCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OverviewRequestReportStarted implements BaseEvent {
  event_type = 'Overview Request Report Started';

  constructor(
    public event_properties?: OverviewRequestReportStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OverviewRequestReportTypeOpened implements BaseEvent {
  event_type = 'Overview Request Report Type Opened';
}

export class OverviewRequestReportTypeSelected implements BaseEvent {
  event_type = 'Overview Request Report Type Selected';

  constructor(
    public event_properties: OverviewRequestReportTypeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OverviewStripeLinkClicked implements BaseEvent {
  event_type = 'Overview Stripe Link Clicked';
}

export class QuickSetupCloseDialogContinueClicked implements BaseEvent {
  event_type = 'Quick Setup Close Dialog Continue Clicked';
}

export class QuickSetupCloseDialogExitClicked implements BaseEvent {
  event_type = 'Quick Setup Close Dialog Exit Clicked';
}

export class QuickSetupCloseDialogOpened implements BaseEvent {
  event_type = 'Quick Setup Close Dialog Opened';

  constructor(
    public event_properties: QuickSetupCloseDialogOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupCreateManuallyClicked implements BaseEvent {
  event_type = 'Quick Setup Create Manually Clicked';
}

export class QuickSetupDescriptionStepCompleted implements BaseEvent {
  event_type = 'Quick Setup Description Step Completed';

  constructor(
    public event_properties: QuickSetupDescriptionStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupDescriptionStepDescriptionFieldOpened implements BaseEvent {
  event_type = 'Quick Setup Description Step Description Field Opened';
}

export class QuickSetupDraftSiteEditDetailsClicked implements BaseEvent {
  event_type = 'Quick Setup Draft Site Edit Details Clicked';

  constructor(
    public event_properties: QuickSetupDraftSiteEditDetailsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupDraftSiteError implements BaseEvent {
  event_type = 'Quick Setup Draft Site Error';
}

export class QuickSetupDraftSiteGoToExperienceClicked implements BaseEvent {
  event_type = 'Quick Setup Draft Site Go To Experience Clicked';

  constructor(
    public event_properties: QuickSetupDraftSiteGoToExperienceClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupDraftSiteSuccess implements BaseEvent {
  event_type = 'Quick Setup Draft Site Success';

  constructor(
    public event_properties: QuickSetupDraftSiteSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupGoToExperienceClicked implements BaseEvent {
  event_type = 'Quick Setup Go To Experience Clicked';
}

export class QuickSetupLocationStepCompleted implements BaseEvent {
  event_type = 'Quick Setup Location Step Completed';

  constructor(
    public event_properties: QuickSetupLocationStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupLocationStepLocationFieldOpened implements BaseEvent {
  event_type = 'Quick Setup Location Step Location Field Opened';
}

export class QuickSetupMediaStepCompleted implements BaseEvent {
  event_type = 'Quick Setup Media Step Completed';

  constructor(
    public event_properties: QuickSetupMediaStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupMediaStepMediaItemDeleted implements BaseEvent {
  event_type = 'Quick Setup Media Step Media Item Deleted';
}

export class QuickSetupMediaStepStockPhotoSelected implements BaseEvent {
  event_type = 'Quick Setup Media Step Stock Photo Selected';

  constructor(
    public event_properties: QuickSetupMediaStepStockPhotoSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupMediaStepUploadFilesClicked implements BaseEvent {
  event_type = 'Quick Setup Media Step Upload Files Clicked';
}

export class QuickSetupNameStepCompleted implements BaseEvent {
  event_type = 'Quick Setup Name Step Completed';

  constructor(
    public event_properties: QuickSetupNameStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupNameStepNameFieldOpened implements BaseEvent {
  event_type = 'Quick Setup Name Step Name Field Opened';
}

export class QuickSetupNextStepClicked implements BaseEvent {
  event_type = 'Quick Setup Next Step Clicked';

  constructor(
    public event_properties: QuickSetupNextStepClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupOpenPreviewClicked implements BaseEvent {
  event_type = 'Quick Setup Open Preview Clicked';

  constructor(
    public event_properties: QuickSetupOpenPreviewClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupOpened implements BaseEvent {
  event_type = 'Quick Setup Opened';

  constructor(
    public event_properties?: QuickSetupOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupPreviousStepClicked implements BaseEvent {
  event_type = 'Quick Setup Previous Step Clicked';

  constructor(
    public event_properties: QuickSetupPreviousStepClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupPriceStepCompleted implements BaseEvent {
  event_type = 'Quick Setup Price Step Completed';

  constructor(
    public event_properties: QuickSetupPriceStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupPriceStepTicketNameFieldOpened implements BaseEvent {
  event_type = 'Quick Setup Price Step Ticket Name Field Opened';
}

export class QuickSetupPriceStepTicketPriceFieldOpened implements BaseEvent {
  event_type = 'Quick Setup Price Step Ticket Price Field Opened';
}

export class QuickSetupSkipStepClicked implements BaseEvent {
  event_type = 'Quick Setup Skip Step Clicked';

  constructor(
    public event_properties: QuickSetupSkipStepClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupStartStepCompleted implements BaseEvent {
  event_type = 'Quick Setup Start Step Completed';

  constructor(
    public event_properties: QuickSetupStartStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupTimeStepCompleted implements BaseEvent {
  event_type = 'Quick Setup Time Step Completed';

  constructor(
    public event_properties: QuickSetupTimeStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupTimeStepFromDateOpened implements BaseEvent {
  event_type = 'Quick Setup Time Step From Date Opened';
}

export class QuickSetupTimeStepFromDateSelected implements BaseEvent {
  event_type = 'Quick Setup Time Step From Date Selected';

  constructor(
    public event_properties: QuickSetupTimeStepFromDateSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupTimeStepFromTimeOpened implements BaseEvent {
  event_type = 'Quick Setup Time Step From Time Opened';
}

export class QuickSetupTimeStepFromTimeSelected implements BaseEvent {
  event_type = 'Quick Setup Time Step From Time Selected';

  constructor(
    public event_properties: QuickSetupTimeStepFromTimeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupTimeStepToDateOpened implements BaseEvent {
  event_type = 'Quick Setup Time Step To Date Opened';
}

export class QuickSetupTimeStepToDateSelected implements BaseEvent {
  event_type = 'Quick Setup Time Step To Date Selected';

  constructor(
    public event_properties: QuickSetupTimeStepToDateSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupTimeStepToTimeOpened implements BaseEvent {
  event_type = 'Quick Setup Time Step To Time Opened';
}

export class QuickSetupTimeStepToTimeSelected implements BaseEvent {
  event_type = 'Quick Setup Time Step To Time Selected';

  constructor(
    public event_properties: QuickSetupTimeStepToTimeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSetupWebsiteFieldOpened implements BaseEvent {
  event_type = 'Quick Setup Website Field Opened';
}

export class SearchOpen implements BaseEvent {
  event_type = 'search.open';

  constructor(
    public event_properties: SearchOpenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchSearched implements BaseEvent {
  event_type = 'search.searched';

  constructor(
    public event_properties: SearchSearchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchSelectResult implements BaseEvent {
  event_type = 'search.selectResult';

  constructor(
    public event_properties: SearchSelectResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchUserCloseDialog implements BaseEvent {
  event_type = 'search.userCloseDialog';

  constructor(
    public event_properties: SearchUserCloseDialogProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeeExperienceOpened implements BaseEvent {
  event_type = 'See Experience Opened';

  constructor(
    public event_properties: SeeExperienceOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignUpConsentChanged implements BaseEvent {
  event_type = 'Sign Up Consent Changed';

  constructor(
    public event_properties: SignUpConsentChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignUpEmailFieldOpened implements BaseEvent {
  event_type = 'Sign Up Email Field Opened';
}

export class SignUpFlowCompleted implements BaseEvent {
  event_type = 'Sign Up Flow Completed';

  constructor(
    public event_properties: SignUpFlowCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignUpLogInClicked implements BaseEvent {
  event_type = 'Sign Up Log In Clicked';
}

export class SignUpPasswordFieldOpened implements BaseEvent {
  event_type = 'Sign Up Password Field Opened';
}

export class SignUpTermsClicked implements BaseEvent {
  event_type = 'Sign Up Terms Clicked';
}

export class SignUpViewed implements BaseEvent {
  event_type = 'Sign Up Viewed';
}

export class StorefrontImplementationExamplesClicked implements BaseEvent {
  event_type = 'Storefront Implementation Examples Clicked';
}

export class StorefrontImplementationExperienceOpened implements BaseEvent {
  event_type = 'Storefront Implementation Experience Opened';
}

export class StorefrontImplementationExperienceSelected implements BaseEvent {
  event_type = 'Storefront Implementation Experience Selected';

  constructor(
    public event_properties: StorefrontImplementationExperienceSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StorefrontImplementationFlowTypeChanged implements BaseEvent {
  event_type = 'Storefront Implementation Flow Type Changed';

  constructor(
    public event_properties: StorefrontImplementationFlowTypeChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StorefrontImplementationLanguageOpened implements BaseEvent {
  event_type = 'Storefront Implementation Language Opened';
}

export class StorefrontImplementationLanguageSelected implements BaseEvent {
  event_type = 'Storefront Implementation Language Selected';

  constructor(
    public event_properties?: StorefrontImplementationLanguageSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StorefrontImplementationLinkCopyButtonClicked implements BaseEvent {
  event_type = 'Storefront Implementation Link Copy Button Clicked';

  constructor(
    public event_properties?: StorefrontImplementationLinkCopyButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StorefrontImplementationLinkKeyboardCopied implements BaseEvent {
  event_type = 'Storefront Implementation Link Keyboard Copied';

  constructor(
    public event_properties?: StorefrontImplementationLinkKeyboardCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StorefrontImplementationViewed implements BaseEvent {
  event_type = 'Storefront Implementation Viewed';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Amplitude] Page Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/%5BAmplitude%5D%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Domain)
   * @param options Amplitude event options.
   */
  amplitudePageViewed(
    properties?: AmplitudePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudePageViewed(properties), options);
  }

  /**
   * Booking Check In Cancelled
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Check%20In%20Cancelled)
   *
   * User clicked cancel in the dialog
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingCheckInCancelled(
    properties: BookingCheckInCancelledProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingCheckInCancelled(properties), options);
  }

  /**
   * Booking Check In Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Check%20In%20Completed)
   *
   * User clicked check in, in the dialog
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingCheckInCompleted(
    properties: BookingCheckInCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingCheckInCompleted(properties), options);
  }

  /**
   * Booking Check In Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Check%20In%20Started)
   *
   * User clicked the check in button and the dialog opened
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingCheckInStarted(
    properties: BookingCheckInStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingCheckInStarted(properties), options);
  }

  /**
   * Booking Details Page Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Details%20Page%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_id)
   * @param options Amplitude event options.
   */
  bookingDetailsPageOpened(
    properties: BookingDetailsPageOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingDetailsPageOpened(properties), options);
  }

  /**
   * Booking Guest Email Copied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Guest%20Email%20Copied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingGuestEmailCopied(
    properties: BookingGuestEmailCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingGuestEmailCopied(properties), options);
  }

  /**
   * Booking Guest Email Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Guest%20Email%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingGuestEmailStarted(
    properties: BookingGuestEmailStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingGuestEmailStarted(properties), options);
  }

  /**
   * Booking Internal Note Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Internal%20Note%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingInternalNoteCompleted(
    properties: BookingInternalNoteCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingInternalNoteCompleted(properties), options);
  }

  /**
   * Booking Internal Note Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Internal%20Note%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingInternalNoteStarted(
    properties: BookingInternalNoteStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingInternalNoteStarted(properties), options);
  }

  /**
   * Booking Move Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Move%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingMoveCompleted(
    properties: BookingMoveCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingMoveCompleted(properties), options);
  }

  /**
   * Booking Move Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Move%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingMoveStarted(
    properties: BookingMoveStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingMoveStarted(properties), options);
  }

  /**
   * Booking Refund Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Refund%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingRefundCompleted(
    properties: BookingRefundCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingRefundCompleted(properties), options);
  }

  /**
   * Booking Refund Exited
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Refund%20Exited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingRefundExited(
    properties: BookingRefundExitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingRefundExited(properties), options);
  }

  /**
   * Booking Refund Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Refund%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingRefundStarted(
    properties: BookingRefundStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingRefundStarted(properties), options);
  }

  /**
   * Booking Reminder TBE Dropdown Filter Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Reminder%20TBE%20Dropdown%20Filter%20Applied)
   *
   * Event that tracks if the Time Before Event for Booking Reminder has been applied.
   *
   * @param properties The event's properties (e.g. booking_reminder_tbe)
   * @param options Amplitude event options.
   */
  bookingReminderTbeDropdownFilterApplied(
    properties: BookingReminderTbeDropdownFilterAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingReminderTbeDropdownFilterApplied(properties), options);
  }

  /**
   * Booking Reminder TBE Dropdown Filter Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Reminder%20TBE%20Dropdown%20Filter%20Opened)
   *
   * Event that tracks if the Time Before Event dropdown for Booking Reminder has been opened.
   *
   * @param options Amplitude event options.
   */
  bookingReminderTbeDropdownFilterOpened(
    options?: EventOptions,
  ) {
    return this.track(new BookingReminderTbeDropdownFilterOpened(), options);
  }

  /**
   * Booking Reminder Toggled Off
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Reminder%20Toggled%20Off)
   *
   * Event indicating that a user has toggled off the booking reminder in Storefront Feature
   *
   * @param options Amplitude event options.
   */
  bookingReminderToggledOff(
    options?: EventOptions,
  ) {
    return this.track(new BookingReminderToggledOff(), options);
  }

  /**
   * Booking Reminder Toggled On
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Reminder%20Toggled%20On)
   *
   * Event indicating that a user has toggled on the booking reminder in Storefront Feature
   *
   * @param options Amplitude event options.
   */
  bookingReminderToggledOn(
    options?: EventOptions,
  ) {
    return this.track(new BookingReminderToggledOn(), options);
  }

  /**
   * Booking Resend Notification Flow Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Resend%20Notification%20Flow%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingResendNotificationFlowCompleted(
    properties: BookingResendNotificationFlowCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingResendNotificationFlowCompleted(properties), options);
  }

  /**
   * Booking Resend Notification Flow Exited
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Resend%20Notification%20Flow%20Exited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingResendNotificationFlowExited(
    properties: BookingResendNotificationFlowExitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingResendNotificationFlowExited(properties), options);
  }

  /**
   * Booking Resend Notification Flow Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Booking%20Resend%20Notification%20Flow%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. booking_amount)
   * @param options Amplitude event options.
   */
  bookingResendNotificationFlowStarted(
    properties: BookingResendNotificationFlowStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingResendNotificationFlowStarted(properties), options);
  }

  /**
   * Bookings Page Next Day Loaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Bookings%20Page%20Next%20Day%20Loaded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_upcoming_booking)
   * @param options Amplitude event options.
   */
  bookingsPageNextDayLoaded(
    properties: BookingsPageNextDayLoadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingsPageNextDayLoaded(properties), options);
  }

  /**
   * Bookings Page Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Bookings%20Page%20Viewed)
   *
   * Event to track when users view the bookings page
   *
   * @param properties The event's properties (e.g. days_until_upcoming_booking)
   * @param options Amplitude event options.
   */
  bookingsPageViewed(
    properties: BookingsPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingsPageViewed(properties), options);
  }

  /**
   * button.click
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/button.click)
   *
   * Generic button click-event, used for all buttons on the Holdbar landingpage/website.
   *
   * Owner: matteo@holdbar.com
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  buttonClick(
    properties: ButtonClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new ButtonClick(properties), options);
  }

  /**
   * Calendar Booking Status Filter Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20Booking%20Status%20Filter%20Applied)
   *
   * User applies the booking status filter from the drop-down menu
   *
   * @param properties The event's properties (e.g. status_filter_list)
   * @param options Amplitude event options.
   */
  calendarBookingStatusFilterApplied(
    properties: CalendarBookingStatusFilterAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarBookingStatusFilterApplied(properties), options);
  }

  /**
   * Calendar Event Status Filter Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20Event%20Status%20Filter%20Applied)
   *
   * User applies the event status filter from the drop-down menu
   *
   * @param properties The event's properties (e.g. status_filter_list)
   * @param options Amplitude event options.
   */
  calendarEventStatusFilterApplied(
    properties: CalendarEventStatusFilterAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarEventStatusFilterApplied(properties), options);
  }

  /**
   * Calendar Experience Filter Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20Experience%20Filter%20Applied)
   *
   * User applies the experience filter from the drop-down menu
   *
   * @param properties The event's properties (e.g. number_of_experiences)
   * @param options Amplitude event options.
   */
  calendarExperienceFilterApplied(
    properties: CalendarExperienceFilterAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarExperienceFilterApplied(properties), options);
  }

  /**
   * Calendar Guide Filter Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20Guide%20Filter%20Applied)
   *
   * User applies the guide filter from the drop-down menu
   *
   * @param properties The event's properties (e.g. number_of_guides)
   * @param options Amplitude event options.
   */
  calendarGuideFilterApplied(
    properties: CalendarGuideFilterAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarGuideFilterApplied(properties), options);
  }

  /**
   * Calendar Language Filter Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20Language%20Filter%20Applied)
   *
   * User applies the language filter from the drop-down menu
   *
   * @param properties The event's properties (e.g. language_list)
   * @param options Amplitude event options.
   */
  calendarLanguageFilterApplied(
    properties: CalendarLanguageFilterAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarLanguageFilterApplied(properties), options);
  }

  /**
   * Calendar Location Filter Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20Location%20Filter%20Applied)
   *
   * User applies the location filter from the drop-down menu
   *
   * @param properties The event's properties (e.g. number_of_locations)
   * @param options Amplitude event options.
   */
  calendarLocationFilterApplied(
    properties: CalendarLocationFilterAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarLocationFilterApplied(properties), options);
  }

  /**
   * Calendar Page Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20Page%20Viewed)
   *
   * User opens calendar page
   *
   * @param properties The event's properties (e.g. date_view_saved)
   * @param options Amplitude event options.
   */
  calendarPageViewed(
    properties?: CalendarPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarPageViewed(properties), options);
  }

  /**
   * Calendar Status Filter Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20Status%20Filter%20Applied)
   *
   * User applies the status filter from the drop-down menu
   *
   * @param properties The event's properties (e.g. status_filter_list)
   * @param options Amplitude event options.
   */
  calendarStatusFilterApplied(
    properties: CalendarStatusFilterAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarStatusFilterApplied(properties), options);
  }

  /**
   * Calendar View Date Picker Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20View%20Date%20Picker%20Opened)
   *
   * User opens the date picker from the calendar view
   *
   * @param options Amplitude event options.
   */
  calendarViewDatePickerOpened(
    options?: EventOptions,
  ) {
    return this.track(new CalendarViewDatePickerOpened(), options);
  }

  /**
   * Calendar View Group Deselected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20View%20Group%20Deselected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. view)
   * @param options Amplitude event options.
   */
  calendarViewGroupDeselected(
    properties: CalendarViewGroupDeselectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarViewGroupDeselected(properties), options);
  }

  /**
   * Calendar View Group Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20View%20Group%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. view)
   * @param options Amplitude event options.
   */
  calendarViewGroupSelected(
    properties: CalendarViewGroupSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarViewGroupSelected(properties), options);
  }

  /**
   * Calendar View Picker Date Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Calendar%20View%20Picker%20Date%20Selected)
   *
   * User selects a date from the date picker
   *
   * @param properties The event's properties (e.g. days_from_current_date)
   * @param options Amplitude event options.
   */
  calendarViewPickerDateSelected(
    properties?: CalendarViewPickerDateSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CalendarViewPickerDateSelected(properties), options);
  }

  /**
   * editCapacity.close
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/editCapacity.close)
   *
   * Triggered when a customer closes the edit capacity window without saving / changing the valueEvent to track when a user closes the capacity editing feature
   *
   * @param options Amplitude event options.
   */
  editCapacityClose(
    options?: EventOptions,
  ) {
    return this.track(new EditCapacityClose(), options);
  }

  /**
   * editCapacity.open
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/editCapacity.open)
   *
   * When a customer clicks the icon to edit event capacity, directly on the event listEvent to track when a user opens the capacity editing feature
   *
   * @param options Amplitude event options.
   */
  editCapacityOpen(
    options?: EventOptions,
  ) {
    return this.track(new EditCapacityOpen(), options);
  }

  /**
   * editCapacity.save
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/editCapacity.save)
   *
   * Event to track when customers change the capacity of an event, directly on the event list
   *
   * @param properties The event's properties (e.g. newCapacity)
   * @param options Amplitude event options.
   */
  editCapacitySave(
    properties: EditCapacitySaveProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditCapacitySave(properties), options);
  }

  /**
   * editor.changeValue
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/editor.changeValue)
   *
   * Triggered when the user changes the value of an editor
   *
   * Owner: Lars Daniel Blom Rasmussen
   *
   * @param properties The event's properties (e.g. editorKey)
   * @param options Amplitude event options.
   */
  editorChangeValue(
    properties: EditorChangeValueProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditorChangeValue(properties), options);
  }

  /**
   * Event Booking Added Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Booking%20Added%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  eventBookingAddedStarted(
    properties: EventBookingAddedStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventBookingAddedStarted(properties), options);
  }

  /**
   * Event Cancellation Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Cancellation%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  eventCancellationStarted(
    properties: EventCancellationStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventCancellationStarted(properties), options);
  }

  /**
   * Event Details Popup Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Details%20Popup%20Viewed)
   *
   * This event tracks when users view the event details popup on the calendar
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  eventDetailsPopupViewed(
    properties: EventDetailsPopupViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventDetailsPopupViewed(properties), options);
  }

  /**
   * Event Details Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Details%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  eventDetailsViewed(
    properties?: EventDetailsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventDetailsViewed(properties), options);
  }

  /**
   * Event Edit Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Edit%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  eventEditStarted(
    properties: EventEditStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventEditStarted(properties), options);
  }

  /**
   * Event Flow Add Time Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Add%20Time%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. number_of_times)
   * @param options Amplitude event options.
   */
  eventFlowAddTimeClicked(
    properties: EventFlowAddTimeClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowAddTimeClicked(properties), options);
  }

  /**
   * Event Flow Booking Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Booking%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. event_capacity)
   * @param options Amplitude event options.
   */
  eventFlowBookingStepCompleted(
    properties: EventFlowBookingStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowBookingStepCompleted(properties), options);
  }

  /**
   * Event Flow Details Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Details%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. event_capacity)
   * @param options Amplitude event options.
   */
  eventFlowDetailsStepCompleted(
    properties: EventFlowDetailsStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowDetailsStepCompleted(properties), options);
  }

  /**
   * Event Flow End Date Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20End%20Date%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowEndDateOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowEndDateOpened(), options);
  }

  /**
   * Event Flow End Date Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20End%20Date%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. date)
   * @param options Amplitude event options.
   */
  eventFlowEndDateSelected(
    properties: EventFlowEndDateSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowEndDateSelected(properties), options);
  }

  /**
   * Event Flow End Time Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20End%20Time%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowEndTimeOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowEndTimeOpened(), options);
  }

  /**
   * Event Flow End Time Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20End%20Time%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. date)
   * @param options Amplitude event options.
   */
  eventFlowEndTimeSelected(
    properties: EventFlowEndTimeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowEndTimeSelected(properties), options);
  }

  /**
   * Event Flow Experience Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Experience%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experience_id)
   * @param options Amplitude event options.
   */
  eventFlowExperienceSelected(
    properties: EventFlowExperienceSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowExperienceSelected(properties), options);
  }

  /**
   * Event Flow Experience Selection Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Experience%20Selection%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowExperienceSelectionOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowExperienceSelectionOpened(), options);
  }

  /**
   * Event Flow Go To Previous Step
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Go%20To%20Previous%20Step)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_event_closes)
   * @param options Amplitude event options.
   */
  eventFlowGoToPreviousStep(
    properties: EventFlowGoToPreviousStepProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowGoToPreviousStep(properties), options);
  }

  /**
   * Event Flow Guide Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Guide%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. number_of_guides)
   * @param options Amplitude event options.
   */
  eventFlowGuideSelected(
    properties: EventFlowGuideSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowGuideSelected(properties), options);
  }

  /**
   * Event Flow Guide Selection Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Guide%20Selection%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowGuideSelectionOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowGuideSelectionOpened(), options);
  }

  /**
   * Event Flow Language Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Language%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. language_list)
   * @param options Amplitude event options.
   */
  eventFlowLanguageSelected(
    properties: EventFlowLanguageSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowLanguageSelected(properties), options);
  }

  /**
   * Event Flow Language Selection Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Language%20Selection%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowLanguageSelectionOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowLanguageSelectionOpened(), options);
  }

  /**
   * Event Flow Location Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Location%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  eventFlowLocationSelected(
    properties: EventFlowLocationSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowLocationSelected(properties), options);
  }

  /**
   * Event Flow Location Selection Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Location%20Selection%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowLocationSelectionOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowLocationSelectionOpened(), options);
  }

  /**
   * Event Flow Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  eventFlowOpened(
    properties: EventFlowOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowOpened(properties), options);
  }

  /**
   * Event Flow Private Status Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Private%20Status%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowPrivateStatusSelected(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowPrivateStatusSelected(), options);
  }

  /**
   * Event Flow Recurring Date Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Recurring%20Date%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowRecurringDateOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowRecurringDateOpened(), options);
  }

  /**
   * Event Flow Recurring Date Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Recurring%20Date%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. date)
   * @param options Amplitude event options.
   */
  eventFlowRecurringDateSelected(
    properties: EventFlowRecurringDateSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowRecurringDateSelected(properties), options);
  }

  /**
   * Event Flow Recurring Type Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Recurring%20Type%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowRecurringTypeOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowRecurringTypeOpened(), options);
  }

  /**
   * Event Flow Recurring Type Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Recurring%20Type%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. is_recurring)
   * @param options Amplitude event options.
   */
  eventFlowRecurringTypeSelected(
    properties?: EventFlowRecurringTypeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowRecurringTypeSelected(properties), options);
  }

  /**
   * Event Flow Seats Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Seats%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. event_capacity)
   * @param options Amplitude event options.
   */
  eventFlowSeatsSelected(
    properties: EventFlowSeatsSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowSeatsSelected(properties), options);
  }

  /**
   * Event Flow Seats Selection Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Seats%20Selection%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowSeatsSelectionOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowSeatsSelectionOpened(), options);
  }

  /**
   * Event Flow Start Date Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Start%20Date%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowStartDateOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowStartDateOpened(), options);
  }

  /**
   * Event Flow Start Date Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Start%20Date%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. date)
   * @param options Amplitude event options.
   */
  eventFlowStartDateSelected(
    properties: EventFlowStartDateSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowStartDateSelected(properties), options);
  }

  /**
   * Event Flow Start Time Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Start%20Time%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  eventFlowStartTimeOpened(
    options?: EventOptions,
  ) {
    return this.track(new EventFlowStartTimeOpened(), options);
  }

  /**
   * Event Flow Start Time Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Start%20Time%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. date)
   * @param options Amplitude event options.
   */
  eventFlowStartTimeSelected(
    properties: EventFlowStartTimeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowStartTimeSelected(properties), options);
  }

  /**
   * Event Flow Time Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Flow%20Time%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_to_booking_closing)
   * @param options Amplitude event options.
   */
  eventFlowTimeStepCompleted(
    properties: EventFlowTimeStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventFlowTimeStepCompleted(properties), options);
  }

  /**
   * Event Message All Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Event%20Message%20All%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  eventMessageAllStarted(
    properties: EventMessageAllStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventMessageAllStarted(properties), options);
  }

  /**
   * event.buttonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/event.buttonClicked)
   *
   * Event for tracking button clicks on the event details page
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  eventButtonClicked(
    properties: EventButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventButtonClicked(properties), options);
  }

  /**
   * Experience Activated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Activated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceActivated(
    properties: ExperienceActivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceActivated(properties), options);
  }

  /**
   * Experience Booking Flow Link Copied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Booking%20Flow%20Link%20Copied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceBookingFlowLinkCopied(
    properties: ExperienceBookingFlowLinkCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceBookingFlowLinkCopied(properties), options);
  }

  /**
   * Experience Booking Flow Link Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Booking%20Flow%20Link%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceBookingFlowLinkOpened(
    properties: ExperienceBookingFlowLinkOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceBookingFlowLinkOpened(properties), options);
  }

  /**
   * Experience Deactivated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Deactivated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceDeactivated(
    properties: ExperienceDeactivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceDeactivated(properties), options);
  }

  /**
   * Experience Details Page Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Details%20Page%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceDetailsPageOpened(
    properties: ExperienceDetailsPageOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceDetailsPageOpened(properties), options);
  }

  /**
   * Experience Event Activated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Activated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventActivated(
    properties: ExperienceEventActivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventActivated(properties), options);
  }

  /**
   * Experience Event Cancellation Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Cancellation%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventCancellationCompleted(
    properties: ExperienceEventCancellationCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventCancellationCompleted(properties), options);
  }

  /**
   * Experience Event Cancellation Exited
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Cancellation%20Exited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventCancellationExited(
    properties: ExperienceEventCancellationExitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventCancellationExited(properties), options);
  }

  /**
   * Experience Event Cancellation Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Cancellation%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventCancellationStarted(
    properties: ExperienceEventCancellationStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventCancellationStarted(properties), options);
  }

  /**
   * Experience Event Creation Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Creation%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventCreationStarted(
    properties: ExperienceEventCreationStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventCreationStarted(properties), options);
  }

  /**
   * Experience Event Deactivated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Deactivated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventDeactivated(
    properties: ExperienceEventDeactivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventDeactivated(properties), options);
  }

  /**
   * Experience Event Details Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Details%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventDetailsOpened(
    properties: ExperienceEventDetailsOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventDetailsOpened(properties), options);
  }

  /**
   * Experience Event Details Three Dots Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Details%20Three%20Dots%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventDetailsThreeDotsClicked(
    properties: ExperienceEventDetailsThreeDotsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventDetailsThreeDotsClicked(properties), options);
  }

  /**
   * Experience Event Edit Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Edit%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventEditStarted(
    properties: ExperienceEventEditStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventEditStarted(properties), options);
  }

  /**
   * Experience Event State Filter Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20State%20Filter%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventStateFilterOpened(
    properties: ExperienceEventStateFilterOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventStateFilterOpened(properties), options);
  }

  /**
   * Experience Event State Filter Updated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20State%20Filter%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventStateFilterUpdated(
    properties: ExperienceEventStateFilterUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventStateFilterUpdated(properties), options);
  }

  /**
   * Experience Event Status Filter Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Status%20Filter%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventStatusFilterOpened(
    properties: ExperienceEventStatusFilterOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventStatusFilterOpened(properties), options);
  }

  /**
   * Experience Event Status Filter Updated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Event%20Status%20Filter%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceEventStatusFilterUpdated(
    properties: ExperienceEventStatusFilterUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceEventStatusFilterUpdated(properties), options);
  }

  /**
   * Experience Go To Pagination
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Go%20To%20Pagination)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. page_number)
   * @param options Amplitude event options.
   */
  experienceGoToPagination(
    properties: ExperienceGoToPaginationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceGoToPagination(properties), options);
  }

  /**
   * Experience Location Filter Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Location%20Filter%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceLocationFilterOpened(
    properties: ExperienceLocationFilterOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceLocationFilterOpened(properties), options);
  }

  /**
   * Experience Location Filter Updated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Location%20Filter%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceLocationFilterUpdated(
    properties: ExperienceLocationFilterUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceLocationFilterUpdated(properties), options);
  }

  /**
   * Experience Three Dots Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experience%20Three%20Dots%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  experienceThreeDotsOpened(
    properties: ExperienceThreeDotsOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperienceThreeDotsOpened(properties), options);
  }

  /**
   * Experiences Page Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Experiences%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_since_most_recent_xp_creation)
   * @param options Amplitude event options.
   */
  experiencesPageViewed(
    properties: ExperiencesPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperiencesPageViewed(properties), options);
  }

  /**
   * generateDescription.addKeyword
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/generateDescription.addKeyword)
   *
   * When adding an individual keyword
   *
   * Owner: Lars Daniel Blom Rasmussen
   *
   * @param properties The event's properties (e.g. fromSuggestion)
   * @param options Amplitude event options.
   */
  generateDescriptionAddKeyword(
    properties: GenerateDescriptionAddKeywordProperties,
    options?: EventOptions,
  ) {
    return this.track(new GenerateDescriptionAddKeyword(properties), options);
  }

  /**
   * generateDescription.close
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/generateDescription.close)
   *
   * When closing the "Generate Experience Description" window manually, without generating text
   *
   * Owner: Lars Daniel Blom Rasmussen
   *
   * @param options Amplitude event options.
   */
  generateDescriptionClose(
    options?: EventOptions,
  ) {
    return this.track(new GenerateDescriptionClose(), options);
  }

  /**
   * generateDescription.failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/generateDescription.failed)
   *
   * Triggered whenever a decription generation failed
   *
   * Owner: Lars Daniel Blom Rasmussen
   *
   * @param properties The event's properties (e.g. experienceId)
   * @param options Amplitude event options.
   */
  generateDescriptionFailed(
    properties: GenerateDescriptionFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GenerateDescriptionFailed(properties), options);
  }

  /**
   * generateDescription.generate
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/generateDescription.generate)
   *
   * Called when using the generate feature
   *
   * Owner: Lars Daniel Blom Rasmussen
   *
   * @param properties The event's properties (e.g. experienceId)
   * @param options Amplitude event options.
   */
  generateDescriptionGenerate(
    properties: GenerateDescriptionGenerateProperties,
    options?: EventOptions,
  ) {
    return this.track(new GenerateDescriptionGenerate(properties), options);
  }

  /**
   * generateDescription.open
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/generateDescription.open)
   *
   * Event is triggered when opening the "Generate experience description" modal view
   *
   * Owner: Lars Daniel Blom Rasmussen
   *
   * @param options Amplitude event options.
   */
  generateDescriptionOpen(
    options?: EventOptions,
  ) {
    return this.track(new GenerateDescriptionOpen(), options);
  }

  /**
   * generateDescription.removeKeyword
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/generateDescription.removeKeyword)
   *
   * When removing an already added keyword
   *
   * Owner: Lars Daniel Blom Rasmussen
   *
   * @param properties The event's properties (e.g. keyword)
   * @param options Amplitude event options.
   */
  generateDescriptionRemoveKeyword(
    properties: GenerateDescriptionRemoveKeywordProperties,
    options?: EventOptions,
  ) {
    return this.track(new GenerateDescriptionRemoveKeyword(properties), options);
  }

  /**
   * Go Back Calendar View Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Go%20Back%20Calendar%20View%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_from_current_date)
   * @param options Amplitude event options.
   */
  goBackCalendarViewChanged(
    properties: GoBackCalendarViewChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GoBackCalendarViewChanged(properties), options);
  }

  /**
   * Go Forward Calendar View Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Go%20Forward%20Calendar%20View%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. view)
   * @param options Amplitude event options.
   */
  goForwardCalendarViewChanged(
    properties: GoForwardCalendarViewChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GoForwardCalendarViewChanged(properties), options);
  }

  /**
   * Go To Day Calendar View Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Go%20To%20Day%20Calendar%20View%20Changed)
   *
   * Event to track when a user switches to the day calendar view
   *
   * @param options Amplitude event options.
   */
  goToDayCalendarViewChanged(
    options?: EventOptions,
  ) {
    return this.track(new GoToDayCalendarViewChanged(), options);
  }

  /**
   * Go To Month Calendar View Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Go%20To%20Month%20Calendar%20View%20Changed)
   *
   * Event indicating when a user changes the month view in the calendar
   *
   * @param options Amplitude event options.
   */
  goToMonthCalendarViewChanged(
    options?: EventOptions,
  ) {
    return this.track(new GoToMonthCalendarViewChanged(), options);
  }

  /**
   * Go To Week Calendar View Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Go%20To%20Week%20Calendar%20View%20Changed)
   *
   * Event indicating when a user switches to the week view in the calendar
   *
   * @param options Amplitude event options.
   */
  goToWeekCalendarViewChanged(
    options?: EventOptions,
  ) {
    return this.track(new GoToWeekCalendarViewChanged(), options);
  }

  /**
   * Guest List Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Guest%20List%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  guestListOpened(
    properties?: GuestListOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GuestListOpened(properties), options);
  }

  /**
   * Intercom Popup Closed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Intercom%20Popup%20Closed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  intercomPopupClosed(
    options?: EventOptions,
  ) {
    return this.track(new IntercomPopupClosed(), options);
  }

  /**
   * Intercom Popup Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Intercom%20Popup%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  intercomPopupOpened(
    options?: EventOptions,
  ) {
    return this.track(new IntercomPopupOpened(), options);
  }

  /**
   * Onboarding List Done Button Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Onboarding%20List%20Done%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingListDoneButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingListDoneButtonClicked(), options);
  }

  /**
   * Onboarding List Event Bullet Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Onboarding%20List%20Event%20Bullet%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingListEventBulletClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingListEventBulletClicked(), options);
  }

  /**
   * Onboarding List Event Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Onboarding%20List%20Event%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingListEventClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingListEventClicked(), options);
  }

  /**
   * Onboarding List Experience Bullet Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Onboarding%20List%20Experience%20Bullet%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingListExperienceBulletClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingListExperienceBulletClicked(), options);
  }

  /**
   * Onboarding List Experience Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Onboarding%20List%20Experience%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingListExperienceClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingListExperienceClicked(), options);
  }

  /**
   * Onboarding List Implementation Bullet Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Onboarding%20List%20Implementation%20Bullet%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingListImplementationBulletClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingListImplementationBulletClicked(), options);
  }

  /**
   * Onboarding List Implementation Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Onboarding%20List%20Implementation%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingListImplementationClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingListImplementationClicked(), options);
  }

  /**
   * Onboarding List Payment Bullet Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Onboarding%20List%20Payment%20Bullet%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingListPaymentBulletClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingListPaymentBulletClicked(), options);
  }

  /**
   * Onboarding List Payment Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Onboarding%20List%20Payment%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingListPaymentClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingListPaymentClicked(), options);
  }

  /**
   * Overview Experience Filter Deselected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Experience%20Filter%20Deselected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. list_of_experience_ids)
   * @param options Amplitude event options.
   */
  overviewExperienceFilterDeselected(
    properties: OverviewExperienceFilterDeselectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OverviewExperienceFilterDeselected(properties), options);
  }

  /**
   * Overview Experience Filter Exited
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Experience%20Filter%20Exited)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  overviewExperienceFilterExited(
    options?: EventOptions,
  ) {
    return this.track(new OverviewExperienceFilterExited(), options);
  }

  /**
   * Overview Experience Filter Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Experience%20Filter%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. number_of_experiences)
   * @param options Amplitude event options.
   */
  overviewExperienceFilterOpened(
    properties: OverviewExperienceFilterOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OverviewExperienceFilterOpened(properties), options);
  }

  /**
   * Overview Experience Filter Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Experience%20Filter%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. list_of_experience_ids)
   * @param options Amplitude event options.
   */
  overviewExperienceFilterSelected(
    properties: OverviewExperienceFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OverviewExperienceFilterSelected(properties), options);
  }

  /**
   * Overview Experience Filter Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Experience%20Filter%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  overviewExperienceFilterStarted(
    options?: EventOptions,
  ) {
    return this.track(new OverviewExperienceFilterStarted(), options);
  }

  /**
   * Overview My Holdbar Page Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20My%20Holdbar%20Page%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  overviewMyHoldbarPageClicked(
    options?: EventOptions,
  ) {
    return this.track(new OverviewMyHoldbarPageClicked(), options);
  }

  /**
   * Overview Page Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  overviewPageViewed(
    properties?: OverviewPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OverviewPageViewed(properties), options);
  }

  /**
   * Overview Report First Date Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Report%20First%20Date%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  overviewReportFirstDateOpened(
    options?: EventOptions,
  ) {
    return this.track(new OverviewReportFirstDateOpened(), options);
  }

  /**
   * Overview Report First Date Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Report%20First%20Date%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_from_current_date)
   * @param options Amplitude event options.
   */
  overviewReportFirstDateSelected(
    properties?: OverviewReportFirstDateSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OverviewReportFirstDateSelected(properties), options);
  }

  /**
   * Overview Report Second Date Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Report%20Second%20Date%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  overviewReportSecondDateOpened(
    options?: EventOptions,
  ) {
    return this.track(new OverviewReportSecondDateOpened(), options);
  }

  /**
   * Overview Report Second Date Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Report%20Second%20Date%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_from_current_date)
   * @param options Amplitude event options.
   */
  overviewReportSecondDateSelected(
    properties?: OverviewReportSecondDateSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OverviewReportSecondDateSelected(properties), options);
  }

  /**
   * Overview Request Report Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Request%20Report%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. report_type)
   * @param options Amplitude event options.
   */
  overviewRequestReportCompleted(
    properties: OverviewRequestReportCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OverviewRequestReportCompleted(properties), options);
  }

  /**
   * Overview Request Report Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Request%20Report%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  overviewRequestReportStarted(
    properties?: OverviewRequestReportStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OverviewRequestReportStarted(properties), options);
  }

  /**
   * Overview Request Report Type Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Request%20Report%20Type%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  overviewRequestReportTypeOpened(
    options?: EventOptions,
  ) {
    return this.track(new OverviewRequestReportTypeOpened(), options);
  }

  /**
   * Overview Request Report Type Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Request%20Report%20Type%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. report_type)
   * @param options Amplitude event options.
   */
  overviewRequestReportTypeSelected(
    properties: OverviewRequestReportTypeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OverviewRequestReportTypeSelected(properties), options);
  }

  /**
   * Overview Stripe Link Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Overview%20Stripe%20Link%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  overviewStripeLinkClicked(
    options?: EventOptions,
  ) {
    return this.track(new OverviewStripeLinkClicked(), options);
  }

  /**
   * Quick Setup Close Dialog Continue Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Close%20Dialog%20Continue%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupCloseDialogContinueClicked(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupCloseDialogContinueClicked(), options);
  }

  /**
   * Quick Setup Close Dialog Exit Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Close%20Dialog%20Exit%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupCloseDialogExitClicked(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupCloseDialogExitClicked(), options);
  }

  /**
   * Quick Setup Close Dialog Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Close%20Dialog%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. current_step)
   * @param options Amplitude event options.
   */
  quickSetupCloseDialogOpened(
    properties: QuickSetupCloseDialogOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupCloseDialogOpened(properties), options);
  }

  /**
   * Quick Setup Create Manually Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Create%20Manually%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupCreateManuallyClicked(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupCreateManuallyClicked(), options);
  }

  /**
   * Quick Setup Description Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Description%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  quickSetupDescriptionStepCompleted(
    properties: QuickSetupDescriptionStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupDescriptionStepCompleted(properties), options);
  }

  /**
   * Quick Setup Description Step Description Field Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Description%20Step%20Description%20Field%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupDescriptionStepDescriptionFieldOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupDescriptionStepDescriptionFieldOpened(), options);
  }

  /**
   * Quick Setup Draft Site Edit Details Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Draft%20Site%20Edit%20Details%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experience_id)
   * @param options Amplitude event options.
   */
  quickSetupDraftSiteEditDetailsClicked(
    properties: QuickSetupDraftSiteEditDetailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupDraftSiteEditDetailsClicked(properties), options);
  }

  /**
   * Quick Setup Draft Site Error
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Draft%20Site%20Error)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupDraftSiteError(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupDraftSiteError(), options);
  }

  /**
   * Quick Setup Draft Site Go To Experience Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Draft%20Site%20Go%20To%20Experience%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. experience_id)
   * @param options Amplitude event options.
   */
  quickSetupDraftSiteGoToExperienceClicked(
    properties: QuickSetupDraftSiteGoToExperienceClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupDraftSiteGoToExperienceClicked(properties), options);
  }

  /**
   * Quick Setup Draft Site Success
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Draft%20Site%20Success)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. storefront_url)
   * @param options Amplitude event options.
   */
  quickSetupDraftSiteSuccess(
    properties: QuickSetupDraftSiteSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupDraftSiteSuccess(properties), options);
  }

  /**
   * Quick Setup Go To Experience Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Go%20To%20Experience%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupGoToExperienceClicked(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupGoToExperienceClicked(), options);
  }

  /**
   * Quick Setup Location Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Location%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  quickSetupLocationStepCompleted(
    properties: QuickSetupLocationStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupLocationStepCompleted(properties), options);
  }

  /**
   * Quick Setup Location Step Location Field Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Location%20Step%20Location%20Field%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupLocationStepLocationFieldOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupLocationStepLocationFieldOpened(), options);
  }

  /**
   * Quick Setup Media Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Media%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  quickSetupMediaStepCompleted(
    properties: QuickSetupMediaStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupMediaStepCompleted(properties), options);
  }

  /**
   * Quick Setup Media Step Media Item Deleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Media%20Step%20Media%20Item%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupMediaStepMediaItemDeleted(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupMediaStepMediaItemDeleted(), options);
  }

  /**
   * Quick Setup Media Step Stock Photo Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Media%20Step%20Stock%20Photo%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. image_url)
   * @param options Amplitude event options.
   */
  quickSetupMediaStepStockPhotoSelected(
    properties: QuickSetupMediaStepStockPhotoSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupMediaStepStockPhotoSelected(properties), options);
  }

  /**
   * Quick Setup Media Step Upload Files Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Media%20Step%20Upload%20Files%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupMediaStepUploadFilesClicked(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupMediaStepUploadFilesClicked(), options);
  }

  /**
   * Quick Setup Name Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Name%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  quickSetupNameStepCompleted(
    properties: QuickSetupNameStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupNameStepCompleted(properties), options);
  }

  /**
   * Quick Setup Name Step Name Field Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Name%20Step%20Name%20Field%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupNameStepNameFieldOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupNameStepNameFieldOpened(), options);
  }

  /**
   * Quick Setup Next Step Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Next%20Step%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. current_step)
   * @param options Amplitude event options.
   */
  quickSetupNextStepClicked(
    properties: QuickSetupNextStepClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupNextStepClicked(properties), options);
  }

  /**
   * Quick Setup Open Preview Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Open%20Preview%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  quickSetupOpenPreviewClicked(
    properties: QuickSetupOpenPreviewClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupOpenPreviewClicked(properties), options);
  }

  /**
   * Quick Setup Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  quickSetupOpened(
    properties?: QuickSetupOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupOpened(properties), options);
  }

  /**
   * Quick Setup Previous Step Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Previous%20Step%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. current_step)
   * @param options Amplitude event options.
   */
  quickSetupPreviousStepClicked(
    properties: QuickSetupPreviousStepClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupPreviousStepClicked(properties), options);
  }

  /**
   * Quick Setup Price Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Price%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  quickSetupPriceStepCompleted(
    properties: QuickSetupPriceStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupPriceStepCompleted(properties), options);
  }

  /**
   * Quick Setup Price Step Ticket Name Field Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Price%20Step%20Ticket%20Name%20Field%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupPriceStepTicketNameFieldOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupPriceStepTicketNameFieldOpened(), options);
  }

  /**
   * Quick Setup Price Step Ticket Price Field Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Price%20Step%20Ticket%20Price%20Field%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupPriceStepTicketPriceFieldOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupPriceStepTicketPriceFieldOpened(), options);
  }

  /**
   * Quick Setup Skip Step Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Skip%20Step%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. current_step)
   * @param options Amplitude event options.
   */
  quickSetupSkipStepClicked(
    properties: QuickSetupSkipStepClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupSkipStepClicked(properties), options);
  }

  /**
   * Quick Setup Start Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Start%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  quickSetupStartStepCompleted(
    properties: QuickSetupStartStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupStartStepCompleted(properties), options);
  }

  /**
   * Quick Setup Time Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Time%20Step%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  quickSetupTimeStepCompleted(
    properties: QuickSetupTimeStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupTimeStepCompleted(properties), options);
  }

  /**
   * Quick Setup Time Step From Date Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Time%20Step%20From%20Date%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupTimeStepFromDateOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupTimeStepFromDateOpened(), options);
  }

  /**
   * Quick Setup Time Step From Date Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Time%20Step%20From%20Date%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. event_start_date)
   * @param options Amplitude event options.
   */
  quickSetupTimeStepFromDateSelected(
    properties: QuickSetupTimeStepFromDateSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupTimeStepFromDateSelected(properties), options);
  }

  /**
   * Quick Setup Time Step From Time Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Time%20Step%20From%20Time%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupTimeStepFromTimeOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupTimeStepFromTimeOpened(), options);
  }

  /**
   * Quick Setup Time Step From Time Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Time%20Step%20From%20Time%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. event_start_date)
   * @param options Amplitude event options.
   */
  quickSetupTimeStepFromTimeSelected(
    properties: QuickSetupTimeStepFromTimeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupTimeStepFromTimeSelected(properties), options);
  }

  /**
   * Quick Setup Time Step To Date Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Time%20Step%20To%20Date%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupTimeStepToDateOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupTimeStepToDateOpened(), options);
  }

  /**
   * Quick Setup Time Step To Date Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Time%20Step%20To%20Date%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. event_end_date)
   * @param options Amplitude event options.
   */
  quickSetupTimeStepToDateSelected(
    properties: QuickSetupTimeStepToDateSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupTimeStepToDateSelected(properties), options);
  }

  /**
   * Quick Setup Time Step To Time Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Time%20Step%20To%20Time%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupTimeStepToTimeOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupTimeStepToTimeOpened(), options);
  }

  /**
   * Quick Setup Time Step To Time Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Time%20Step%20To%20Time%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. event_end_date)
   * @param options Amplitude event options.
   */
  quickSetupTimeStepToTimeSelected(
    properties: QuickSetupTimeStepToTimeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupTimeStepToTimeSelected(properties), options);
  }

  /**
   * Quick Setup Website Field Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Quick%20Setup%20Website%20Field%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  quickSetupWebsiteFieldOpened(
    options?: EventOptions,
  ) {
    return this.track(new QuickSetupWebsiteFieldOpened(), options);
  }

  /**
   * search.open
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/search.open)
   *
   * Event to track when a user clicks on the search button in the sidebar navigation
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  searchOpen(
    properties: SearchOpenProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchOpen(properties), options);
  }

  /**
   * search.searched
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/search.searched)
   *
   * Event to track query and result count
   *
   * @param properties The event's properties (e.g. query)
   * @param options Amplitude event options.
   */
  searchSearched(
    properties: SearchSearchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchSearched(properties), options);
  }

  /**
   * search.selectResult
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/search.selectResult)
   *
   * Event to track when a user either clicks or presses the enter key on a search result
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  searchSelectResult(
    properties: SearchSelectResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchSelectResult(properties), options);
  }

  /**
   * search.userCloseDialog
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/search.userCloseDialog)
   *
   * Event indicating that a user has closed the search dialog
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  searchUserCloseDialog(
    properties: SearchUserCloseDialogProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchUserCloseDialog(properties), options);
  }

  /**
   * See Experience Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/See%20Experience%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_until_first_event_starts)
   * @param options Amplitude event options.
   */
  seeExperienceOpened(
    properties: SeeExperienceOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeeExperienceOpened(properties), options);
  }

  /**
   * Sign Up Consent Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Sign%20Up%20Consent%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. consent)
   * @param options Amplitude event options.
   */
  signUpConsentChanged(
    properties: SignUpConsentChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignUpConsentChanged(properties), options);
  }

  /**
   * Sign Up Email Field Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Sign%20Up%20Email%20Field%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signUpEmailFieldOpened(
    options?: EventOptions,
  ) {
    return this.track(new SignUpEmailFieldOpened(), options);
  }

  /**
   * Sign Up Flow Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Sign%20Up%20Flow%20Completed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. is_invite)
   * @param options Amplitude event options.
   */
  signUpFlowCompleted(
    properties: SignUpFlowCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignUpFlowCompleted(properties), options);
  }

  /**
   * Sign Up Log In Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Sign%20Up%20Log%20In%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signUpLogInClicked(
    options?: EventOptions,
  ) {
    return this.track(new SignUpLogInClicked(), options);
  }

  /**
   * Sign Up Password Field Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Sign%20Up%20Password%20Field%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signUpPasswordFieldOpened(
    options?: EventOptions,
  ) {
    return this.track(new SignUpPasswordFieldOpened(), options);
  }

  /**
   * Sign Up Terms Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Sign%20Up%20Terms%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signUpTermsClicked(
    options?: EventOptions,
  ) {
    return this.track(new SignUpTermsClicked(), options);
  }

  /**
   * Sign Up Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Sign%20Up%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signUpViewed(
    options?: EventOptions,
  ) {
    return this.track(new SignUpViewed(), options);
  }

  /**
   * Storefront Implementation Examples Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Storefront%20Implementation%20Examples%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  storefrontImplementationExamplesClicked(
    options?: EventOptions,
  ) {
    return this.track(new StorefrontImplementationExamplesClicked(), options);
  }

  /**
   * Storefront Implementation Experience Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Storefront%20Implementation%20Experience%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  storefrontImplementationExperienceOpened(
    options?: EventOptions,
  ) {
    return this.track(new StorefrontImplementationExperienceOpened(), options);
  }

  /**
   * Storefront Implementation Experience Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Storefront%20Implementation%20Experience%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. selected_experience)
   * @param options Amplitude event options.
   */
  storefrontImplementationExperienceSelected(
    properties: StorefrontImplementationExperienceSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StorefrontImplementationExperienceSelected(properties), options);
  }

  /**
   * Storefront Implementation Flow Type Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Storefront%20Implementation%20Flow%20Type%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. selected_flow_type)
   * @param options Amplitude event options.
   */
  storefrontImplementationFlowTypeChanged(
    properties: StorefrontImplementationFlowTypeChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StorefrontImplementationFlowTypeChanged(properties), options);
  }

  /**
   * Storefront Implementation Language Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Storefront%20Implementation%20Language%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  storefrontImplementationLanguageOpened(
    options?: EventOptions,
  ) {
    return this.track(new StorefrontImplementationLanguageOpened(), options);
  }

  /**
   * Storefront Implementation Language Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Storefront%20Implementation%20Language%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. language)
   * @param options Amplitude event options.
   */
  storefrontImplementationLanguageSelected(
    properties?: StorefrontImplementationLanguageSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StorefrontImplementationLanguageSelected(properties), options);
  }

  /**
   * Storefront Implementation Link Copy Button Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Storefront%20Implementation%20Link%20Copy%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. integration_link)
   * @param options Amplitude event options.
   */
  storefrontImplementationLinkCopyButtonClicked(
    properties?: StorefrontImplementationLinkCopyButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StorefrontImplementationLinkCopyButtonClicked(properties), options);
  }

  /**
   * Storefront Implementation Link Keyboard Copied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Storefront%20Implementation%20Link%20Keyboard%20Copied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. integration_link)
   * @param options Amplitude event options.
   */
  storefrontImplementationLinkKeyboardCopied(
    properties?: StorefrontImplementationLinkKeyboardCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StorefrontImplementationLinkKeyboardCopied(properties), options);
  }

  /**
   * Storefront Implementation Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/holdbar/Backoffice/events/main/latest/Storefront%20Implementation%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  storefrontImplementationViewed(
    options?: EventOptions,
  ) {
    return this.track(new StorefrontImplementationViewed(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
