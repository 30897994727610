import styled from '@emotion/styled';
import { Button, lightTheme } from '@holdbar-com/pixel';
import { MediaItem } from '@holdbar-com/utils-types';
import { CheckRounded, ClearOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';

import { DraggableVideoPreview } from '../../../Components/DraggableGallery/DraggableVideoPreview/DraggableVideoPreview';
import { getURLFromMediaItem } from '../../../Components/DraggableGallery/Media';
import { useLazyImage } from '../../../Hooks/useLazyImage';
import { Progress } from './media-upload';

export const Media = ({
  mediaItem,
  handleClear,
  handleClick,
  uploaded,
  progress,
}: {
  mediaItem: MediaItem & { localUrl: string };
  handleClear?: (mediaItem: MediaItem) => void;
  handleClick?: () => void;
  uploaded?: boolean;
  progress?: number;
}) => {
  const url = getURLFromMediaItem(mediaItem);

  const { state } = useLazyImage({ source: url });

  return (
    <Stack
      position="relative"
      justifyContent="center"
      alignItems="center"
      onClick={() => (!uploaded ? handleClick?.() : {})}
      sx={{
        opacity: uploaded || progress ? '0.75' : 'unset',
        ':hover':
          !uploaded && handleClick ? { cursor: 'pointer', opacity: 0.75 } : {},
        transition: 'opacity 0.1s ease-in-out',
      }}
    >
      {mediaItem.type === 'video' && mediaItem.provider === 'holdbar' ? (
        <Box
          sx={{
            width: '130px',
            height: '130px',
            borderRadius: '12px',
            aspectRatio: '1 / 1',
          }}
        >
          <DraggableVideoPreview
            styles={videoJSStyles}
            options={{
              autoplay: true,
              loop: true,
              muted: true,
              sources: [
                {
                  src: mediaItem.playback.hls,
                  type: 'application/x-mpegURL',
                },
              ],
            }}
          />
        </Box>
      ) : (
        <Box
          component="img"
          src={state === 'loading' ? mediaItem.localUrl : url}
          sx={{
            display: 'block',
            width: '130px',
            height: '130px',
            borderRadius: '12px',
            aspectRatio: '1 / 1',
            objectFit: 'cover',
          }}
        />
      )}
      {handleClear && (
        <ClearButton
          type="button"
          variant="text"
          size="small"
          onClick={() => handleClear(mediaItem)}
        >
          <ClearOutlined fontSize="inherit" />
        </ClearButton>
      )}
      {uploaded && (
        <UploadedLabel>
          <CheckRounded fontSize="inherit" />
        </UploadedLabel>
      )}
      {typeof progress === 'number' && <Progress progress={progress} />}
    </Stack>
  );
};

const ClearButton = styled(Button)({
  position: 'absolute',
  top: '8px',
  right: '8px',
  borderRadius: '100%',
  height: '18px',
  width: '18px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  fontSize: '14px',
  transition: 'backgroudn 0.1 ease-in-out',
  span: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&:hover': {
    background: lightTheme.palette.neutral.n100,
  },
});

const UploadedLabel = styled(Stack)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  justifyContent: 'center',
  alignItems: 'center',
  background: lightTheme.palette.success.s400,
  borderRadius: '100%',
  height: '48px',
  width: '48px',
  color: 'white',
  fontSize: '20px',
});

const videoJSStyles = {
  parent: {
    cursor: 'pointer',
    'background-color': '#F6F6F6',
    width: '100%',
    height: '100%',
    outline: 'none',
    'border-radius': '12px',
  },
  video: {
    'object-fit': 'cover',
    'border-radius': '12px',
  },
};
