import { lightTheme, Text } from '@holdbar-com/pixel';
import { MediaItem } from '@holdbar-com/utils-types';
import { Stack } from '@mui/material';
import randomBytes from 'randombytes';
import { useCallback } from 'react';

import { useTranslate } from '../../../Hooks/useTranslate';
import { trackQuickSetupMediaStepStockPhotoSelected } from '../../../tracking/quick-setup/step-events';
import { Media } from './media';
import { useUploader } from './use-uploader';

type MediaWithStockPhotoIndex = MediaItem & {
  localUrl: string;
  stockPhotoIndex?: number;
};

export const StockPhotoSelector = ({
  id,
  uploadedMedia,
  onChange,
}: {
  id: string;
  uploadedMedia: MediaItem[];
  onChange: (media: MediaItem[]) => void;
}) => {
  const { t } = useTranslate('onboarding.dialog.step.media.stockPhotos');

  const onUploaded = useCallback(
    (newMedia: MediaItem[]) => {
      onChange([...uploadedMedia, ...newMedia]);
    },
    [onChange, uploadedMedia]
  );

  const { onSelect, filestoBeUploaded } = useUploader({
    id,
    fileType: 'cover',
    modelType: 'experience',
    onUploaded,
  });

  const handleSelect = async (url: string, stockPhotoIndex: number) => {
    const file = await fetch(url, { mode: 'no-cors' })
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], randomBytes(16).toString('hex'), {
            type: 'image/jpeg',
          })
      );

    onSelect([file], stockPhotoIndex);

    trackQuickSetupMediaStepStockPhotoSelected(url);
  };

  return (
    <Stack gap={1}>
      <Text variant="medium">{t('title')}</Text>
      <Stack direction="row" gap={2} maxWidth="100%" overflow="auto">
        {Array(10)
          .fill(undefined)
          .map((_, index) => {
            const stockPhoto: MediaItem & { localUrl: string } = {
              type: 'image',
              url: `/stock-photo/${index}.jpg`,
              preview: undefined,
              mimeType: 'image/jpeg',
              localUrl: `/stock-photo/resized/${index}.jpg`,
            };
            return (
              <Media
                key={index}
                mediaItem={stockPhoto}
                handleClick={() => handleSelect(stockPhoto.url, index)}
                progress={
                  filestoBeUploaded.find(
                    (file) => file.stockPhotoIndex === index
                  )?.progress
                }
                uploaded={Boolean(
                  uploadedMedia.find(
                    (media) =>
                      (media as MediaWithStockPhotoIndex)?.stockPhotoIndex ===
                      index
                  )
                )}
              />
            );
          })}
      </Stack>
      <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
        {t('disclaimer')}
      </Text>
    </Stack>
  );
};
