import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useLoaderData,
} from 'react-router';

export function loader({ params }: LoaderFunctionArgs) {
  const companyId = params.companyId;
  const receiverEmail = params.receiverEmail;
  return {
    companyId,
    receiverEmail,
  };
}

export function action({}: ActionFunctionArgs) {
  return {};
}

export function ConnectionDetailsPage() {
  const loaderData = useLoaderData() as {
    companyId: string;
    receiverEmail: string;
  };
  return (
    <>
      <h1>Connection details</h1>
      <p>
        {loaderData.companyId}
        <br />
        {loaderData.receiverEmail}
      </p>
    </>
  );
}
