import { Text } from '@holdbar-com/pixel';
import { renderDate } from '@holdbar-com/utils-date';
import { Connection } from '@holdbar-com/utils-types';
import { ChevronRight } from '@mui/icons-material';
import { Box, Card, Divider, Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ActionFunctionArgs,
  Link,
  LoaderFunctionArgs,
  useSubmit,
} from 'react-router-dom';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';

import { getConnections, getInvitation, refreshToken } from '../../../Api';
import { getPublicCompanyProfile, getUserinfo } from '../../../Api/Profiles';
import { StringAvatar } from '../../../Components/StringAvatar';
import { useAuth } from '../../../Hooks/useAuth';
import { useTranslate } from '../../../Hooks/useTranslate';
import { useAuthStore as authStore } from '../../../Store/useAuthStore';
import {
  Account,
  ChooseAccountDialog,
} from '../components/choose-account-dialog';
import { ConnectViewHeader } from './connect-header';
import { connectStore } from './connect-store';
import { RouterLinkButton } from './router-link-button';

type LoaderData = {
  connections: {
    key: string;
    createdAt: string;
    receiverLabel: string;
    state: Connection['state'];
  }[];
  receivedRequests: {
    key: string;
    createdAt: string;
    receiverLabel: string;
    token: string;
  }[];
  memberOfCompanies?: Account[];
  token?: string;
  accountChosen?: boolean;
};

async function getPublicCompany(companyId?: string): Promise<Account | null> {
  if (!companyId) {
    return null;
  }
  try {
    const profile = await getPublicCompanyProfile(companyId);
    return profile === null
      ? null
      : {
          id: profile.id,
          name: profile.name,
          logo: profile?.pictures?.logo?.url,
        };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function loader({
  request,
}: LoaderFunctionArgs): Promise<LoaderData> {
  const connectState = connectStore.getState();

  const url = new URL(request.url);
  const hasChosenAccount =
    connectState.accountChosen ||
    url.searchParams.get('account_chosen') === 'true';
  const invitationToken = url.searchParams.get('invitation');

  const [allConnections, invitation] = await Promise.all([
    getConnections(),
    invitationToken ? getInvitation(invitationToken) : Promise.resolve(null),
  ]);

  const invitationReceiverUnknown =
    !!invitation &&
    !allConnections.some(
      (c) =>
        c.companyId === invitation.companyId &&
        c.receiverEmail === invitation.receiverEmail
    );

  if (!!invitation && invitationReceiverUnknown) {
    allConnections.push(invitation);
  }

  const enrichedItems = await Promise.all(
    allConnections.map(async (item) => {
      const company = await getPublicCompany(item.connectedCompanyId);
      const receiverLabel = company?.name ?? item.receiverEmail;

      return {
        ...item,
        key: `${encodeURIComponent(item.companyId)}/${encodeURIComponent(item.receiverEmail)}`,
        receiverLabel,
      };
    })
  );

  const connections: LoaderData['connections'] = [];
  const receivedRequests: LoaderData['receivedRequests'] = [];

  for (const item of enrichedItems) {
    if (item.state === 'requested') {
      receivedRequests.push({
        key: item.key,
        createdAt: item.createdAt,
        receiverLabel: item.receiverLabel,
        token: item.token,
      });
    } else {
      connections.push({
        key: item.key,
        createdAt: item.createdAt,
        receiverLabel: item.receiverLabel,
        state: item.state,
      });
    }
  }

  if (!invitationToken) {
    return {
      connections,
      receivedRequests,
    };
  }

  if (hasChosenAccount) {
    return {
      connections,
      receivedRequests,
      token: invitationToken,
      accountChosen: hasChosenAccount,
    };
  }

  const userInfo = await getUserinfo();
  const memberOfCompanies = (
    await Promise.all(
      userInfo.memberOfCompanies.map(async (companyId) =>
        getPublicCompany(companyId)
      )
    )
  ).filter((x: Account | null): x is Account => x !== null);
  return {
    connections,
    receivedRequests,
    memberOfCompanies,
    token: invitationToken,
    accountChosen: hasChosenAccount,
  };
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const invitationToken = new URL(request.url).searchParams.get('invitation');

  const selectedCompanyId = formData.get('org');
  if (!selectedCompanyId || typeof selectedCompanyId !== 'string') {
    return {
      status: 400,
    };
  }

  const { auth, setAuth } = authStore.getState();
  if (!auth?.refresh_token) {
    return {
      status: 401,
    };
  }
  const data = await refreshToken(auth.refresh_token, selectedCompanyId);
  setAuth(data);

  connectStore.getState().chooseAccount();

  return (window.location.href = `/settings/connect?invitation=${invitationToken}&account_chosen=true`);
}

export const ConnectSettingsList = () => {
  const flags = useFlags();
  const navigate = useNavigate();
  const { canAccess } = useAuth();
  const loaderData = useLoaderData() as LoaderData;
  const { t } = useTranslate('connect');
  const submit = useSubmit();

  if (!flags.featureConnectedAccounts) {
    navigate('/dashboard', { replace: true });
    // Return something so we don't run any of the below code when redirecting
    return <></>;
  }

  if (!canAccess('admin')) {
    return <>Not admin</>;
  }

  return (
    <>
      <Stack gap={4}>
        <ConnectViewHeader />

        <Stack component={Card} sx={{ px: 4, py: 3 }} gap={2}>
          <Text as="h3" variant="medium" fontSize="small">
            {t('invitations', {
              numberOfInvitations: loaderData.receivedRequests.length,
            })}
          </Text>
          <Divider />
          <Stack component="ul" gap={3}>
            {loaderData.receivedRequests.map((request) => (
              <Box
                component="li"
                key={request.key}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: '50% 50%', md: '1.5fr 1fr 1fr' },
                  alignItems: 'center',
                }}
              >
                <Stack direction={'row'} gap={1} alignItems="center">
                  <StringAvatar
                    value={request.receiverLabel.toUpperCase()}
                    sx={{ display: { xs: 'none', md: 'unset' } }}
                  />
                  <span>{request.receiverLabel}</span>
                </Stack>
                <Box
                  component="span"
                  sx={{
                    textAlign: 'center',
                    display: { xs: 'none', md: 'unset' },
                  }}
                >
                  {renderDate(request.createdAt, 'dd.MM.yyyy')}
                </Box>
                <Stack direction={'row'} justifyContent="end" gap={1}>
                  <RouterLinkButton
                    href={`decline/${request.token}`}
                    variant="secondary"
                  >
                    {t('decline')}
                  </RouterLinkButton>
                  <RouterLinkButton
                    href={`accept/${request.token}`}
                    variant="primary"
                  >
                    {t('accept')}
                  </RouterLinkButton>
                </Stack>
              </Box>
            ))}
          </Stack>
        </Stack>

        <Stack component={Card} sx={{ px: 4, py: 3 }} gap={2}>
          <Text as="h3" variant="medium" fontSize="small">
            {t('connections')}
          </Text>
          <Divider />
          <Stack component="ul" gap={3}>
            {loaderData.connections.map((c) => (
              <Link to={'details/' + c.key} key={c.key}>
                <Box
                  component="li"
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr 1fr', md: '1.5fr 1fr 1fr' },
                    alignItems: 'center',
                  }}
                >
                  <Stack direction={'row'} gap={1} alignItems="center">
                    <StringAvatar
                      value={c.receiverLabel.toUpperCase()}
                      sx={{ display: { xs: 'none', md: 'unset' } }}
                    />
                    <span>{c.receiverLabel}</span>
                  </Stack>

                  <Box
                    component="span"
                    sx={{
                      textAlign: 'center',
                      display: { xs: 'none', md: 'unset' },
                    }}
                  >
                    {renderDate(c.createdAt, 'dd.MM.yyyy')}
                  </Box>
                  <Stack direction={'row'} justifyContent="end" gap={4}>
                    <span>{t(c.state)}</span>
                    <ChevronRight />
                  </Stack>
                </Box>
              </Link>
            ))}
          </Stack>
        </Stack>
        <Outlet />
      </Stack>
      {loaderData.token && loaderData.memberOfCompanies && (
        <ChooseAccountDialog
          companies={loaderData.memberOfCompanies}
          onSubmit={(data) => {
            submit(data, { method: 'POST' });
          }}
        />
      )}
    </>
  );
};
