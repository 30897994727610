import {
  CompanyProfile,
  PublicCompanyProfile,
  StorefrontFeatures,
} from '@holdbar-com/utils-types';

import { IMyInfo } from '../Hooks/useProfile';
import axios from './index';
import { HoldbarApiError } from './Invitation';

export interface TokenPayload {
  sub: string;
  alt_subs?: string[];
  auth_time?: Date;
  email?: string;
  org?: string;
  scope?: string;
  role?: string;
  memberOfCompanies: string[];
}

export const getCompanyProfile = async () => {
  if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const { data } = await axios.get<CompanyProfile>(
    `${process.env.REACT_APP_API_PROFILES_URL}/company`
  );

  return data;
};

export const getPublicCompanyProfile = async (companyId: string) => {
  if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const { data } = await axios.get<PublicCompanyProfile>(
    `${process.env.REACT_APP_API_PROFILES_URL}/company/${companyId}/public`
  );

  return data;
};

export const getCompanyUsers = async () => {
  if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const { data } = await axios.get<any>(
    `${process.env.REACT_APP_API_PROFILES_URL}/company/users`
  );

  return data;
};

export const getMyProfile = async () => {
  if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const { data } = await axios.get<any>(
    `${process.env.REACT_APP_API_PROFILES_URL}/me`
  );

  return data;
};

export const updateCompanyProfile = async (
  payload: Partial<CompanyProfile>,
  createNew = false
) => {
  if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  if (createNew) {
    const path = `${process.env.REACT_APP_API_PROFILES_URL}/companies`;
    const { data } = await axios.post<Partial<CompanyProfile>>(path, {
      ...payload,
    });

    return data;
  }

  const path = `${process.env.REACT_APP_API_PROFILES_URL}/company`;

  const { data } = await axios.put<Partial<CompanyProfile>>(path, {
    ...payload,
  });

  return data;
};

export const updateCompanyFeature = async <T extends keyof StorefrontFeatures>(
  feature: T,
  payload: StorefrontFeatures[T]
) => {
  if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const path = `${process.env.REACT_APP_API_PROFILES_URL}/company/features/${feature}`;

  const { data } = await axios.put(path, { ...payload });

  return data;
};

export const updateMyProfile = async (payload: IMyInfo) => {
  if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES_URL is not defined'
    );
  }

  const { data } = await axios.put<IMyInfo>(
    `${process.env.REACT_APP_API_PROFILES_URL}/me`,
    { ...payload }
  );

  return data;
};

export const firstLogin = async () => {
  if (typeof process.env.REACT_APP_API_PROFILES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PROFILES is not defined'
    );
  }

  return axios.post(
    `${process.env.REACT_APP_API_PROFILES_URL}/company/first-login`
  );
};

export const removeUser = async (userId: string) => {
  try {
    if (typeof process.env.REACT_APP_API_ACCOUNTS_URL === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    await axios.delete(
      `${process.env.REACT_APP_API_ACCOUNTS_URL}/company/users/${userId}`
    );

    return {
      error: false,
    };
  } catch (error) {
    const apiError = error as HoldbarApiError;
    return {
      error: true,
      code: apiError.response?.data.error,
    };
  }
};

export const getUserinfo = async () => {
  const url = process.env.REACT_APP_API_ACCOUNTS_URL;
  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }
  const { data } = await axios.get<TokenPayload>(`${url}/userinfo`);

  return data;
};
