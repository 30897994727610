import { ExperienceSortOption } from '@holdbar-com/utils-types';

import type { IExperience } from '../Hooks/useExperience';
import axios, { IShallowSuccessResponse } from './index';

export const getExperience = async (id: string) => {
  if (typeof process.env.REACT_APP_API_EXPERIENCES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await axios.get<IExperience>(
    `${process.env.REACT_APP_API_EXPERIENCES_URL}/${id}`
  );

  return data;
};

export type ExperienceQueryOptions = {
  sortExperiencesBy?: ExperienceSortOption;
  defaultLanguage?: string;
  language?: string;
};

export const getExperiences = async (options?: ExperienceQueryOptions) => {
  if (typeof process.env.REACT_APP_API_EXPERIENCES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await axios.get<IExperience[]>(
    `${process.env.REACT_APP_API_EXPERIENCES_URL}`,
    { params: options }
  );

  return data;
};

export const updateExperience = async (
  id: string,
  payload: Record<string, any>
) => {
  if (typeof process.env.REACT_APP_API_EXPERIENCES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await axios.put<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_EXPERIENCES_URL}/${id}`,
    payload
  );

  return data;
};

export const deleteExperience = async (id: string) => {
  if (typeof process.env.REACT_APP_API_EXPERIENCES_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await axios.delete<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_EXPERIENCES_URL}/${id}`
  );

  return data;
};
