import { Button, lightTheme } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../domain/use_booking_details_context';
import { useBookingDetailsActions } from './domain/use_booking_details_actions';
import { BookingDetailsActionsMobile } from './ui/BookingDetailsActionsMobile';
import { BookingDetailsActionsPopoverItem } from './ui/BookingDetailsActionsMobileItem';

export const BookingDetailsActions = () => {
  const { t } = useTranslate('dialogs.booking');
  const flags = useFlags();
  const { isLg } = useResponsive();

  const { booking, cancelAvailable, moveAvailable, checkInAvailable } =
    useBookingDetailsContext();

  const {
    handleCancel,
    handleRefund,
    handleMoveBooking,
    handleCheckInBooking,
  } = useBookingDetailsActions();

  const showCancelButton =
    booking?.status !== 'cancelled' &&
    (!booking?.startDateTime || new Date(booking.startDateTime) > new Date());

  const showRefundButton = !booking?.refund?.date && !!booking?.transaction;

  const showMoveBookingButton =
    flags['featureMoveBooking'] === true &&
    (showRefundButton || showCancelButton);

  const showButtons =
    (showCancelButton || showRefundButton || showMoveBookingButton) &&
    booking?.status !== 'moved';

  if (!showButtons || !booking) {
    return null;
  }

  if (isLg) {
    return (
      <BookingDetailsActionsMobile>
        {showCancelButton && (
          <BookingDetailsActionsPopoverItem
            label={t('actions.tertiary')}
            onClick={handleCancel}
            disabled={!cancelAvailable}
          />
        )}
        {showRefundButton && (
          <BookingDetailsActionsPopoverItem
            label={t('actions.secondary')}
            onClick={() => handleRefund('popover')}
          />
        )}
        {showMoveBookingButton && (
          <BookingDetailsActionsPopoverItem
            label={t('actions.moveBooking')}
            onClick={() => handleMoveBooking('popover')}
            disabled={!moveAvailable}
          />
        )}
      </BookingDetailsActionsMobile>
    );
  }

  return (
    <Stack direction={'row'} gap={1}>
      {showCancelButton && (
        <Button
          variant={'secondary'}
          size={'small'}
          onClick={handleCancel}
          style={{
            color: cancelAvailable ? lightTheme.palette.error.e300 : undefined,
          }}
          disabled={!cancelAvailable}
        >
          {t('actions.tertiary')}
        </Button>
      )}
      {showRefundButton && (
        <Button
          variant={'secondary'}
          size={'small'}
          onClick={() => handleRefund('button')}
        >
          {t('actions.secondary')}
        </Button>
      )}
      {showMoveBookingButton && (
        <Button
          variant={'secondary'}
          size={'small'}
          onClick={() => handleMoveBooking('button')}
          disabled={!moveAvailable}
        >
          {t('actions.moveBooking')}
        </Button>
      )}
      {checkInAvailable && (
        <Button
          size="small"
          variant="primary"
          onClick={() => handleCheckInBooking()}
        >
          {t('actions.checkIn')}
        </Button>
      )}
    </Stack>
  );
};
