import {
  ConnectedItem,
  Connection,
  RequestItem,
} from '@holdbar-com/utils-types';

import api from './index';

const API_ENDPOINT = process.env.REACT_APP_INTERNAL_API_GATEWAY;

export async function getConnections() {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const response = await api.get<Connection[]>(
    `${API_ENDPOINT}/v1/connect/connections`
  );

  return response.data;
}

export async function getInvitation(token: string) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const response = await api.get<RequestItem | null>(
    `${API_ENDPOINT}/v1/connect/invitations/${token}`
  );

  return response.data;
}

export async function createConnection({
  email,
  sharedExperiences,
}: {
  email: string;
  sharedExperiences: ConnectedItem['sharedExperiences'];
}) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(`${API_ENDPOINT}/v1/connect/connections`, {
    email,
    sharedExperiences,
  });
}

export async function acceptConnectionRequest(
  token: string,
  sharedExperiences: ConnectedItem['sharedExperiences']
) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(`${API_ENDPOINT}/v1/connect/invitations/${token}`, {
    accepted: true,
    sharedExperiences,
  });
}

export async function declineConnectionRequest(token: string) {
  if (typeof API_ENDPOINT === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  await api.post(`${API_ENDPOINT}/v1/connect/invitations/${token}`, {
    accepted: false,
  });
}
